import React, { useEffect } from "react";
import { data } from "../../../../../assets/data/category";
import { authMatchDemand } from "../../../../../core/authorization";
import { useAppSelector } from "../../../../../store/hooks";
import Datatable from "../../../../common/datatable";
import LoadingIndicator from "../../../../common/loading";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import { useGetCandidatesBySkillsQuery } from "../../features/demands-query";
import { ActionButtons } from "./assign-buttons";

function AssignableCandidates() {
  const { selectedRow, query } = useAppSelector((state) => state.demandsSlice);
  const { refetch, isFetching, data } = useGetCandidatesBySkillsQuery(
    JSON.stringify(query)
  );

  useEffect(() => {
    refetch();
  }, [selectedRow])
  

  return (
    <>
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <>
          <Datatable
            height={350}
            customButtons={ActionButtons}
            quikFilter={false}
            quikExport={false}
            actionName={"View"}
            columnNames={["name", "skills"]}
            isDefaultShowActions={authMatchDemand.hasAccess()}
            myData={data.data.filter(
              (e: ICandidates) =>
                !selectedRow.assigned_candidates.includes(e._id)
            )}
          />
        </>
      )}
    </>
  );
}

export default AssignableCandidates;
