import React, { useEffect, useState } from "react";

import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Form,
  Input,
  Col,
  Row,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  FormDataToJson,
  ViewJSONData,
} from "../../../../../utils/AppUtils";

import { setRecruiterQuery, setShowAssignRecruiters, setShowMatch } from "../../features/demands-slice";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import AssignedRecruiters from "./assigned-recruiters";
import { ActionButtons } from "./assign-buttons";
import AssignableRecruiters from "./assignable-recruiters";

function AssignRecruiters(props: any) {
  const appDispatch = useAppDispatch();
  const { selectedRow, recruiterQuery } = useAppSelector((state) => state.demandsSlice);

  const onSubmit = (event: any) => {
    event.preventDefault();
    let { full_name } = FormDataToJson(new FormData(event.target));
    full_name = full_name === "" ? undefined : { $regex: `${full_name}`, $options: "i" };
    appDispatch(setRecruiterQuery({...recruiterQuery, full_name }));
  };

  return (
    <Modal isOpen={props.isOpen} backdrop="static">
      <ModalHeader toggle={() => appDispatch(setShowAssignRecruiters(false))}>
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Assign Recruiters
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row sm="12">
            <Col sm="6">
              <Input name="full_name" type="text" />
            </Col>
           
            <Button color="primary">Fetch</Button>
          </Row>
        </Form>
        <AssignableRecruiters />
        { selectedRow.assigned_recruiter_id ? 
        <AssignedRecruiters /> : 'Recruiter is not assigned Yet'}
      </ModalBody>
      {/* <ModalFooter>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Button type="submit" color="primary">
            Assign
          </Button>
        )}
        <Button
          type="button"
          color="secondary"
          onClick={() => appDispatch(setShowMatch(false))}
        >
          Close
        </Button>
      </ModalFooter> */}
    </Modal>
  );
}

export default AssignRecruiters;
