import React, { useState } from "react";
import Footer from "../../components/common/footer";
import Header from "../../components/common/header_components/header";
import RightSidebar from "../../components/common/right-sidebar";
import Sidebar from "../../components/common/sidebar_components/sidebar";


const DashboardWrapper = (props : any) => {
	const initialState = {
		ltr: true,
		divName: "RTL",
	};

	const [side, setSide] = useState(initialState);
	return (
		<div>
			<div className="page-wrapper">
				<Header />
				<div className="page-body-wrapper">
					<Sidebar />
					<RightSidebar />
					<div className="page-body">{props.children}</div>
					<Footer />
				</div>
			</div>
		</div>
	);
};
export default DashboardWrapper;
