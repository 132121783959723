import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";


import AppRoutes from "./core/navigation/app-routes";
import { useSelector, useDispatch } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setAppConfiguration } from "./store/appStateSlice";
import { RootState } from "./store/store";
import { errorToast } from "./store/toastSlice";
import ApiPaths, { ConfigIds } from "./utils/ApiPaths";
import ApiService from "./utils/ApiService";
const App = () => {
  const appState = useSelector(
    (state: RootState) => state.appState.configuration
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(appState).length == 0) {
      const appConfig = reactLocalStorage.getObject("appConfig");
      if (Object.keys(appConfig).length == 0) {
        fetchAppConfiguration();
      } else {
        dispatch(setAppConfiguration(appConfig));
      }
    }
  }, []);

  const fetchAppConfiguration = async () => {
    ApiService.Get(`${ApiPaths.appConfiguration}/${ConfigIds.appConfigId}`)
      .then((res) => {
        res = res.data;
        if (res.code === 200) {
          let items = res.data[0].items;
          reactLocalStorage.setObject("appConfig", items);
          dispatch(setAppConfiguration(items));
        } else {
          errorToast(res.message);
        }
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
    //
  };
  

  return (
    <>
      <ToastContainer autoClose={2000} />
      <BrowserRouter>
       <AppRoutes/>
      </BrowserRouter>
    </>
  );
};
export default App;
