import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  CardSubtitle,
  CardDeck,
} from "reactstrap";
import Datatable from "../common/datatable";
import LoadingIndicator from "../common/loading";
import stats from "../../assets/images/dashboard/stats.png";

function ThankYou() {
  let movies;

return (
<div className="App">
      <Card
        body
        inverse
        style={{ borderColor: "#333", maxWidth:500, marginTop:'30vh' }}
      >
        <CardTitle style={{ color:'#89CFF0', fontSize:30 ,alignContent:"center", alignItems:"center"}}>Thank you!</CardTitle>
        <CardText>
        You are successfully registered on Mentoscale.
        Your details are being sent for verification.
        <br/><br/>
        Thank you.
        </CardText>
      </Card>
    </div>
);

}

export default ThankYou;
