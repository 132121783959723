import { createSlice } from "@reduxjs/toolkit";
import { ICandidatesStatus } from "../../../../core/interfaces";
import { IDemandsCandidates } from "./demandscandidates-interface";
export interface IDemandsCandidatesState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  isBulkOpen: boolean;
  dataList: IDemandsCandidates[];
  editableData: IDemandsCandidates;
  candidatesStatusList : ICandidatesStatus[];
}

const inialState: IDemandsCandidatesState = {
  isEmpty: false,
  isOpen: false,
  message: "loading",
  isBulkOpen: false,
  dataList: [] as IDemandsCandidates[],
  editableData: {} as IDemandsCandidates,
  candidatesStatusList : []
};

export const DemandsCandidatesSlice = createSlice({
  name: "demandscandidates_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IDemandsCandidates;
      state.isOpen = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
      if (action.payload.length == 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setBulkOpen: (state, action) => {
      state.isBulkOpen = action.payload;
    },
    setCandidatesStatusList: (state, action) => {
      state.candidatesStatusList = action.payload;
    },
  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setBulkOpen,
  setCandidatesStatusList,
} = DemandsCandidatesSlice.actions;

export default DemandsCandidatesSlice.reducer;
