import { createSlice } from "@reduxjs/toolkit";
import { IColleges } from "./colleges-interface";
export interface ICollegesState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  isBulkOpen: boolean;
  dataList: IColleges[];
  editableData: IColleges;
}

const inialState: ICollegesState = {
  isEmpty: false,
  isOpen: false,
  isBulkOpen: false,
  message: "loading",
  dataList: [] as IColleges[],
  editableData: {} as IColleges,
};

export const CollegesSlice = createSlice({
  name: "colleges_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IColleges;
      state.isOpen = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
      if (action.payload.length == 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setBulkOpen: (state, action) => {
      state.isBulkOpen = action.payload;
    },
  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setBulkOpen,
} = CollegesSlice.actions;

export default CollegesSlice.reducer;
