import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import LoadingIndicator from "../../common/loading";
import { ICandidates } from "./candidates-interface";

const CandidatesForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [platformMmodule, setPlatformModule] = useState("SAP");
  const onSubmit = (event: any) => {
    setLoading(true);
    props.onSubmitForm(event);
    setLoading(false);
  };

  const defValue: ICandidates = props.editableData ?? ({} as ICandidates);
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onCloseHandler}
      backdrop="static"
    >
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Candidates
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Name:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.name}
                className="form-control mb-0"
                name="name"
                id="name-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Mail Id:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.mail_id}
                className="form-control mb-0"
                name="mail_id"
                id="mail_id-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Phone Number:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.phone_number}
                className="form-control mb-0"
                name="phone_number"
                id="phone_number-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Mentoscale Grade:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.mentoscale_grade}
                className="form-control mb-0"
                name="mentoscale_grade"
                id="mentoscale_grade-text"
                type="select"
              >
                <option>Fresher</option>
                <option>Standard</option>
                <option>Silver</option>
                <option>Gold</option>
                <option>Platinum</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Experience Level:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.experience_level}
                className="form-control mb-0"
                name="experience_level"
                id="experience_level-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Relevent Experience:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.relevent_experience}
                className="form-control mb-0"
                name="relevent_experience"
                id="relevent_experience-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Current CTC:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.ctc}
                className="form-control mb-0"
                name="ctc"
                id="ctc-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Expected CTC:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.expected_ctc}
                className="form-control mb-0"
                name="expected_ctc"
                id="expected_ctc-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Current Company:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.current_company}
                className="form-control mb-0"
                name="current_company"
                id="current_company-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Current Designation:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.current_designation}
                className="form-control mb-0"
                name="current_designation"
                id="current_designation-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Notice Period:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.notice_period}
                className="form-control mb-0"
                name="notice_period"
                id="notice_period-select"
                type="select"
              >
                <option>Currently serving notice period</option>
                <option>Immediate</option>
                <option>7 Days</option>
                <option>10 Days</option>
                <option>15 Days</option>
                <option>45 Days</option>
                <option>1 Month</option>
                <option>2 Month</option>
                <option>3 Month</option>
                <option>More than 3 Months</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Present Organisation Since When:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={
                  defValue.present_organisation_since_when &&
                  defValue.present_organisation_since_when
                    .toString()
                    .substring(0, 10)
                }
                className="form-control mb-0"
                name="present_organisation_since_when"
                id="present_organisation_since_when-date"
                type="date"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Domain:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.domain}
                className="form-control mb-0"
                name="domain"
                id="domain-text"
                type="select"
                required
              >
                <option>ERP</option>
                <option>Non-ERP</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Platform:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.platform}
                className="form-control mb-0"
                name="platform"
                id="platform-text"
                type="select"
                onChange={(e) => setPlatformModule(e.target.value)}
                required
              >
                <option>SAP</option>
                <option>CSD</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Module:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.module}
                className="form-control mb-0"
                name="module"
                id="module-text"
                type="select"
                required
              >
                {domainMmodules[platformMmodule].map((e) => (
                  <option>{e}</option>
                ))}
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Skills:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.skills}
                className="form-control mb-0"
                name="skills"
                id="skills-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Date Of Last Contact:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={
                  defValue.date_of_last_contact &&
                  defValue.date_of_last_contact.toString().substring(0, 10)
                }
                className="form-control mb-0"
                name="date_of_last_contact"
                id="date_of_last_contact-text"
                type="date"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Location:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.location}
                className="form-control mb-0"
                name="location"
                id="location-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Referred By:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.referred_by}
                className="form-control mb-0"
                name="referred_by"
                id="referred_by-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Recruiter:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.recruiter}
                className="form-control mb-0"
                name="recruiter"
                id="recruiter-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Organisation:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.organisation}
                className="form-control mb-0"
                name="organisation"
                id="organisation-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Deployed Company:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.deployed_company}
                className="form-control mb-0"
                name="deployed_company"
                id="deployed_company-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Deployed Date:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={
                  defValue.deployed_date &&
                  defValue.deployed_date.toString().substring(0, 10)
                }
                className="form-control mb-0"
                name="deployed_date"
                id="deployed_date-date"
                type="date"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Dream Company:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.dream_company}
                className="form-control mb-0"
                name="dream_company"
                id="dream_company-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Dream Role:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.dream_role}
                className="form-control mb-0"
                name="dream_role"
                id="dream_role-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Comments:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.comments}
                className="form-control mb-0"
                name="comments"
                id="comments-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>

          <ModalFooter>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CandidatesForm;
export const domainMmodules: Record<string, string[]> = {
  SAP: [
    "SAP PP QM",

    "SAP PLM",
    "SAP",
    "SAP SD",
    "SAP ABAP",
    "SAP ABAP ODATA",
    "ABAP WEBDYNPRO",
    "ABAP CDS",
    "SAP PP",
    "SAP MM",
    "SAP QM",
    "SAP WM",
    "SAP FICO",
    "SAP CS",
    "SAP CO",
    "SAP BI",
    "SAP BW",
    "SAP HR/HCM",
    "SAP SCM",
    "SAP Net weaver",
    "SAP HANA",
    "SD MM",
    "SAP ECC FI",
    "SAP PM",
    "SAP FIORI",
    "SAP BASIS",
    "SAP FSCM",
    "SAP LE",
    "SAP CLM",
    "SAP CRM",
    "SAP ARIBA",
    "SAP HYBRIS",
    "SAP GRC",
    "SAP APO",
    "SAP IBP",
    "SAP PPDS",
  ],

  CSD: [
    "Java",
    "Angular",
    "Front end ",
    "Full stack",
    "Devops",
    "MERN stack",
    "Backend microservices",
    "UI developer",
    "NODE JS",
    "REACT JS",
    "JAVA",
    "PYTHON",
    "C#",
    "SQL",
    ".Net",
    "Andriod developer",
    "iOS developer",
    "Angular js",
    "AWS",
    "QA testing",
    "Manual Testing",
    "Automation testing",
    "MVC",
    "ASP.Net",
    "JQUERY Mobile",
    "Microservices",
    "Bootstrap",
    "Java Script",
    "Webservices",
    "AJAX",
    "Power Apps",
    "SFDC",
    "Network Architect",
    "Oracle",
    "Power BI",
    "Graphics programer",
    "Developer Advocate",
    "Game developer",
    "GameplayNetwork Engineer",
    "Graphics Architect",
    "Game programmer",
  ],
};
