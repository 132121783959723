import { result } from "lodash";
import React, { useState } from "react";
import { Input } from "reactstrap";
import { ICandidatesStatus } from "../../../../core/interfaces";
import { useGetCandidatesStatusQuery } from "../../../../store/apis-service/candidates-status-api";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { errorToast } from "../../../../store/toastSlice";
import { ActionComfirmation, ViewJSONData } from "../../../../utils/AppUtils";
import LoadingIndicator from "../../../common/loading";
import { useAssignCanditatesMutation } from "../../demands/features/demands-query";
import { updateAssignedCandidate } from "../../demands/features/demands-slice";

function ApproveActions(row: any) {
  let appDispatch = useAppDispatch();
  
  const [currentStatus, setStatus] = useState(row.data.status)
  const [assignCandidate, { isLoading: isAssigning, status }] =
    useAssignCanditatesMutation();

    
  // const [isAssigning, assignCandidate] = useState(false);
  const { selectedRow } = useAppSelector((state) => state.demandsSlice);
  const { refetch, isFetching, data } = useGetCandidatesStatusQuery("");
  const assignHandler = async (event: any) => {
    const status = event.target.value;
    let result = await ActionComfirmation()
    if(!result.value){ return}
    
    setStatus(status);
    const is_rejected = status === 'REJECTED';
    assignCandidate({
      body: {
        assigned_candidates: row.data.candidate_id,
        is_assigned: is_rejected,
        status: status,
      },
      id: row.data.demand_id,
    }).then((data: any) => {
      if (data.data.status == "Ok") {
        row.node.setDataValue('status',status)
      } else {
        errorToast(data.data.message);
      }
    });
  };

  if (isAssigning) {
    return (
      <div style={{ width: 25 }}>
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div>
      {/* <span onClick={() => ViewJSONData(row.data)}>
        <i
          className="fa fa-eye"
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: "#0000ff",
          }}
        ></i>
      </span> */}
      {isFetching ? (
        <div style={{ width: 25 }}>
          <LoadingIndicator />
        </div>
      ) : (
       
        <Input name="region" type="select" defaultValue={currentStatus} onChange={assignHandler}>
          {data.data.map((e: ICandidatesStatus) => (
            <option key={e.status}>{e.status}</option>
          ))}
        </Input>
      )}
      {/* <span onClick={() => assignHandler(row,false)}>
          <i
            className="fa fa-check"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}
          ></i>
        </span>

        <span onClick={() => assignHandler(row,true)}>
          <i
            className="fa fa-times"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
            }}
          ></i>
        </span> */}
    </div>
  );
}

export default ApproveActions;
