import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  Col,
  Row,
} from "reactstrap";
import { Configs } from "../../../../assets/data/configs";
import { FormDataToJson } from "../../../../utils/AppUtils";
import LoadingIndicator from "../../../common/loading";
import { IAccounts, IRpoc } from "../features/accounts-interface";

const AccountsForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const defValue: IAccounts = props.editableData ?? {};
  const initialObject : IRpoc = { full_name: "", contact_no: "", email: "", is_active: true };

  const [getPOC, setPOC] = useState([defValue.poc || initialObject]);
  const [getRPOC, setRPOC] = useState(defValue.rpoc || [initialObject]);
  
  const submitHandler = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const object: any = FormDataToJson(new FormData(event.target));
    const array: any[] = [];
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const element = object[key];
        let kStrSplitted = key.split("_", -1);
        let lastValue = kStrSplitted[kStrSplitted.length - 1];
        if (lastValue.length == 1) {
          kStrSplitted.pop();
          const objKey = kStrSplitted.join("_");
          let val = array[Number(lastValue)];
          if (val) {
            val[objKey] = element;
          } else {
            let obj: any = {};
            obj[objKey] = element;
            array[Number(lastValue)] = obj;
          }
          delete object[key];
        }
      }
    }
    object.poc = getPOC[0];
    object.rpoc = getRPOC//array.slice(1, array.length);
    props.onSubmitForm(object);
    setLoading(false);
  };
  const ContactElement = (props: any) => (
    <Row className="col-md-12">
      <Input
        autoComplete="false"
        className="col-md-3 input-margin"
        style={{ marginRight: "5px", marginTop: "5px" }}
        type="text"
        required
        defaultValue={props.dataPoint.full_name}
        placeholder="Full Name"
        name={`full_name_${props.index}`}
        onBlur={(e) =>
          props.onChange({ ...props.dataPoint, full_name: e.target.value })
        }
      />
      <Input
        className="col-md-3 input-margin"
        style={{ marginRight: "5px", marginTop: "5px" }}
        type="tel"
        required
        defaultValue={props.dataPoint.contact_no}
        name={`contact_no_${props.index}`}
        placeholder="Contact No."
        onBlur={(e) =>
          props.onChange({ ...props.dataPoint, contact_no: e.target.value })
        }
      />
      <Input
        className="col-md-4 input-margin"
        style={{ marginRight: "5px", marginTop: "5px" }}
        type="email"
        required
        defaultValue={props.dataPoint.email}
        name={`email_${props.index}`}
        placeholder="Email Id"
        onBlur={(e) =>
          props.onChange({ ...props.dataPoint, email: e.target.value })
        }
        // onBlur={(e) => {
        //   console.log("email", e.target.value);
        // }}
      />

      {(!!props.index && getRPOC.length > 1) && (
        <span onClick={props.remove}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
            }}
          />
        </span>
      )}
    </Row>
  );


  return (
    <Modal size="lg" style={{maxWidth: '700px', width: '100%'}} isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader toggle={props.onCloseHandler} >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Accounts
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation" onSubmit={submitHandler}>
          <h4>Company Info</h4>
          <Row>
            <Col sm="12">
              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>Company Name
                </Label>
                <Input
                  className="form-control col-md-7"
                  id="company_name"
                  type="text"
                  required
                  name="company_name"
                  defaultValue={defValue.company_name}
                />
              </div>

              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>Company Address
                </Label>
                <Input
                  defaultValue={defValue.company_address}
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="text"
                  required
                  name="company_address"
                />
              </div>
              <div className="form-group row">
                <Label className="col-xl-3 col-md-4"  title="Recruiter Point Of Contact">
                  <span>*</span> POC
                </Label>
                <div className="col-sm-9 row">
                  {getPOC.map((e, index) => (
                    <ContactElement
                      key={`pof ${index}`}
                      dataPoint={e}
                      index={index}
                      onChange={(val: any) => {
                       
                        getPOC[index] = { ...getPOC[index], ...val };
                        setPOC([...getPOC]);
                      }}
                      remove={() => {
                        if(!!getPOC[index]._id){
                          getPOC[index].is_active = false
                        }else{
                          getPOC.splice(index, 1);
                        }
                        setPOC([...getPOC]);
                      }}
                    ></ContactElement>
                  ))}
                </div>
              </div>

              <div className="form-group row">
                <Label className="col-xl-3 col-md-4" title="Recruiter Point Of Contact">
                  <span>*</span>ROPC
                </Label>
                <div className="col-sm-9 row">
                  {getRPOC.map((e, index) => (
                    e.is_active ?
                    <ContactElement
                      key={`rpoc ${index + 1}`}
                      dataPoint={e}
                      index={index + 1}
                      onChange={(val: any) => {
                        let lRpoc = JSON.parse(JSON.stringify(getRPOC))
                        lRpoc[index] = {
                          ...getRPOC[index],
                          ...val,
                        };
                        setRPOC([...lRpoc]);
                      }}
                      remove={() => {
                        let lRpoc = JSON.parse(JSON.stringify(getRPOC))
                        
                        if(!!lRpoc[index]._id){
                          lRpoc[index].is_active = false
                        }else{
                          lRpoc.splice(index, 1);
                        }
                        setRPOC([...lRpoc]);
                      }}
                    />:<></>
                  ))}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-9"></div>
                <div className="col-sm-2">
                  <Button onClick={() => setRPOC([...getRPOC, initialObject])}>
                    ADD
                  </Button>
                </div>
              </div>
              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>GST Number
                </Label>
                <Input
                  defaultValue={defValue.gst_number}
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="text"
                  required
                  name="gst_number"
                />
              </div>
              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>PAN Number
                </Label>
                <Input
                  defaultValue={defValue.pan_number}
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="text"
                  required
                  name="pan_number"
                />
              </div>
              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>TAN Number
                </Label>
                <Input
                  defaultValue={defValue.tan_number}
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="text"
                  required
                  name="tan_number"
                />
              </div>

              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>Region
                </Label>
                <Input
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="select"
                  required
                  name="region"
                  defaultValue={defValue.region}
                >
                  {Configs.regions.map((e) => (
                    <option>{e.name}</option>
                  ))}
                </Input>
              </div>

              <div className="form-group row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span>Classification
                </Label>
                <Input
                  className="form-control col-md-7"
                  id="validationCustom1"
                  type="select"
                  required
                  name="classification"
                  defaultValue={defValue.classification}
                >
                  {Configs.classifications.map((e) => (
                    <option>{e.name}</option>
                  ))}
                </Input>
              </div>
            </Col>
          </Row>
          <ModalFooter>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AccountsForm;
