import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../core/common/base-query";
import { IPutData } from "../../core/interfaces";
import ApiPaths from "../../utils/ApiPaths";

export const candidatesStatusApi = createApi({
    reducerPath: "candidatesStatusApi",
    baseQuery: baseQuery,
    keepUnusedDataFor: 5*60,
    endpoints: (builder) => ({
      getCandidatesStatus: builder.query<any, any>({
        query: () => `${ApiPaths.candidatesStatus}`,
      })
    }),
  });

  export const { useGetCandidatesStatusQuery } = candidatesStatusApi;
  