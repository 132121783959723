import { createSlice } from "@reduxjs/toolkit";
import { IAccounts } from "./accounts-interface";
export interface IAccountsState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  isBulkOpen: boolean;
  dataList: IAccounts[];
  selectedRow: IAccounts;
  editableData: IAccounts;
  isShowAssignRecruiters: boolean;
  recruiterQuery: any;
}

let defaultRecruiterQuery = { role: "RECRUITER", region: "NORTH" };

const inialState: IAccountsState = {
  isEmpty: false,
  isOpen: false,
  message: "loading",
  isBulkOpen: false,
  dataList: [] as IAccounts[],
  selectedRow: {} as IAccounts,
  editableData: {} as IAccounts,
  isShowAssignRecruiters: false,
  recruiterQuery: defaultRecruiterQuery,
};



export const accountSlice = createSlice({
  name: "accounts_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IAccounts;
      state.isOpen = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setShowAssignRecruiters: (state, action) => {
      state.isShowAssignRecruiters = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
      if (action.payload.length == 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setBulkOpen: (state, action) => {
      state.isBulkOpen = action.payload;
    },
    setSelected: (state, action) => {
      state.selectedRow = action.payload || ({} as IAccounts);
    },
    updateAssignedRecruiter: (state, action) => {
      if (action.payload.is_assigned) {
        state.selectedRow.assigned_recruiter_id = "";
        state.selectedRow.assigned_recruiter_name = "UNASSIGNED";
      } else {
        state.selectedRow.assigned_recruiter_id = action.payload.recruiter_id;
        state.selectedRow.assigned_recruiter_name =
          action.payload.recruiter_name;
      }
    },
    setRecruiterQuery: (state, action) => {
      state.recruiterQuery = action.payload || defaultRecruiterQuery;
    },

  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setBulkOpen,
  setSelected,
  updateAssignedRecruiter,
  setShowAssignRecruiters,
  setRecruiterQuery
} = accountSlice.actions;

export default accountSlice.reducer;
