
    import { Fragment, useEffect } from "react";
    import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
    import { Authorization } from "../../../core/authorization";
    import { useAppDispatch, useAppSelector } from "../../../store/hooks";
    import { errorToast, successToast, warnToast } from "../../../store/toastSlice";
    import ApiPaths from "../../../utils/ApiPaths";
    import ApiService from "../../../utils/ApiService";
    import { FormDataToJson } from "../../../utils/AppUtils";
    import Breadcrumb from "../../common/breadcrumb";
    import Datatable from "../../common/datatable";
    import LoadingIndicator from "../../common/loading";
    import { setDataList, setEditable, setEmpty, setOpen, setBulkOpen,  } from "./features/account-recruiters-slice";
    import AccountRecruitersForm from "./components/account-recruiters-form";

    function AccountRecruiters(props: any) {
      const appDispatch = useAppDispatch()
      const { editableData,dataList,isEmpty,message,isOpen,isBulkOpen } = useAppSelector((state) => state.accountRecruitersSlice);
      const onOpenModal = () => appDispatch(setOpen(true));
      const onCloseModal = () => {appDispatch(setOpen(false))};

      const fetchList = async (mounted: boolean) => {
        appDispatch(setEmpty(false))
        await ApiService.Get(
          ApiPaths.assignAccountRecruiters
        )
          .then((res) => {
            if (mounted) {
              if (res.data.status == "Ok") {
                appDispatch(setDataList(res.data.data))
              } else {
                errorToast(res.data.msg);
                appDispatch(setEmpty(true))
              }
            }
          })
          .catch((err) => {
            appDispatch(setEmpty(true))
            errorToast("Connection Error");
          });
      };
       
      useEffect(() => {
        appDispatch(setDataList([]))
        let mounted = true;
        fetchList(mounted);
        return () => {
          mounted = false;
        };
      }, []);

      const onSubmitForm = async (event: {
        preventDefault: () => void;
        target: HTMLFormElement | undefined;
      }) => {
        event.preventDefault();
        const formData: any = FormDataToJson(new FormData(event.target));
         let res =editableData._id ? ApiService.Patch(`${ApiPaths.assignAccountRecruiters}/${editableData._id}`, formData) : ApiService.Post(ApiPaths.assignAccountRecruiters, formData);
    
          res.then((res: any) => {
         
            if (res.data.success) {
              onCloseModal();
              appDispatch(setDataList([]))
              fetchList(true);
            } else {
              warnToast(res.data.message);
            }
          })
          .catch((err: any) => {
            console.log("error ====> ", err.response);
          });
      };
      
      const handleDelete = (data: any) => {
        ApiService.Delete(`${ApiPaths.assignAccountRecruiters}/${data._id}`)
          .then((res) => {
            successToast(res.data.message);
            appDispatch(setDataList([]));
            fetchList(true);
          })
          .catch((err) => {
            console.log("error", err);
          });
      };
      

      const handleEdit = (data: any) => {
        appDispatch(setEditable(data));
      };


      const onDownload = () => {
        window.open(
          "https://templates-doc.s3.ap-south-1.amazonaws.com/college_template.csv"
        );
      };
    
      const onUpload = () => {
        appDispatch(setBulkOpen(true));
      };

        return (
          <Fragment>
            <Breadcrumb title={'Account Recruiters'} parent="account" />
      
            {/* <!-- Container-fluid starts--> */}
            <Container fluid={true}>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="btn-popup pull-right">
      
                       {/* {
                         Authorization.canCreate() && <Button
                          type="button"
                          color="primary"
                          onClick={onOpenModal}
                          data-toggle="modal"
                          data-original-title="test"
                          data-target="#exampleModal"
                        >
                          Add AccountRecruiters
                        </Button>} */}
                      </div>
                      <div className="btn-popup pull-right">
                      
                        {isOpen && (
                          <>
                          
                          < AccountRecruitersForm
                            editableData={editableData}
                            isOpen={isOpen}
                            onCloseHandler={onCloseModal}
                            onSubmitForm={onSubmitForm}
                          ></AccountRecruitersForm></>
                        )}
                      </div>
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        {!(dataList.length > 0) ? (
                          <LoadingIndicator isEmpty={isEmpty} message={message} />
                        ) : (
                          <Datatable
                            title="AccountRecruiters"
                            myData={dataList}
                            multiSelectOption={false}
                            pageSize={10}
                            pagination={true}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            myClass={undefined}
                            onPageChanged={undefined}
                            currentPage={undefined}
                            hideColumns={undefined}
                            actionName={"Actions"}
                            customButtons={undefined}
                          />
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* <!-- Container-fluid Ends--> */}
          </Fragment>
        );
      }
      
      export default  AccountRecruiters;