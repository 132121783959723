import React, { useEffect, useState } from "react";

import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Form,
  Input,
  Col,
  Row,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  FormDataToJson,
  ViewJSONData,
} from "../../../../../utils/AppUtils";

import { setQuery, setShowMatch } from "../../features/demands-slice";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import AssignedCandidates from "./assigned-candidates";
import { ActionButtons } from "./assign-buttons";
import AssignableCandidates from "./assignable-candidates";

function DemandsMatch(props: any) {
  const appDispatch = useAppDispatch();
  const { selectedRow } = useAppSelector((state) => state.demandsSlice);

  const onSubmit = (event: any) => {
    event.preventDefault();
    let { name, skills } = FormDataToJson(new FormData(event.target));
    name = name === "" ? undefined : { $regex: `${name}`, $options: "i" };
    skills = skills === "Select Skill" ? undefined : { $in: skills.split(",") };
    appDispatch(setQuery({ name, skills }));
  };

  return (
    <Modal isOpen={props.isOpen} backdrop="static">
      <ModalHeader toggle={() => appDispatch(setShowMatch(false))}>
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Assign Candidates
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row sm="12">
            <Col sm="4">
              <Input name="name" type="text" />
            </Col>
            <Col sm="4">
              <Input
                className="form-control"
                name="skills"
                id="account_name-text"
                type="select"
              >
                <option>Select Skill</option>
                {selectedRow.skill.map((e: any) => (
                  <option>{e}</option>
                ))}
              </Input>
            </Col>
            <Button color="primary">Fetch</Button>
          </Row>
        </Form>
        <AssignableCandidates />
      
        <AssignedCandidates />
      </ModalBody>
      {/* <ModalFooter>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Button type="submit" color="primary">
            Assign
          </Button>
        )}
        <Button
          type="button"
          color="secondary"
          onClick={() => appDispatch(setShowMatch(false))}
        >
          Close
        </Button>
      </ModalFooter> */}
    </Modal>
  );
}

export default DemandsMatch;
