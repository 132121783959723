import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import { Configs } from "../../../../assets/data/configs";
import { IUserState } from "../../../../core/interfaces";
import LoadingIndicator from "../../../common/loading";

const PuForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true)
    props.onSubmitForm(event);
    setLoading(false)
  };

  const defValue: IUserState = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader 
      
      // toggle={props.onCloseHandler}
      
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Platform User
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup>
            <Input
              required
              name="full_name"
              type="text"
              defaultValue={defValue.full_name}
              className="form-control"
              placeholder="Full Name"
              
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              name="username"
              defaultValue={defValue.username}
              type="email"
              className="form-control"
              placeholder="Email (Mentoscale ID)"
              
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              name="mobile_no"
              type="text"
              defaultValue={defValue.mobile_no}
              className="form-control"
              placeholder="Mobile No."
              
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              name="region"
              type="select"
              defaultValue={defValue.region}
              className="form-control"
              placeholder="Region"
            >
              {Configs.regions.map((e) => (
                <option key={e.name}>{e.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              required
              name="role"
              type="select"
              className="form-control"
              placeholder="Role"
              defaultValue={defValue.role}
            >
              {Configs.roles.map((e) => (
                <option key={e.name}>{e.name}</option>
              ))}
            </Input>
          </FormGroup>
          <div className="form-terms">
            <div className="custom-control custom-checkbox mr-sm-2">
              <Input
                type="checkbox"
                className="custom-control-input"
                id="customControlAutosizing"
              />
            </div>
          </div>
          <ModalFooter>
         { isLoading ? 
         <LoadingIndicator/>
         :
            <Button type="submit" color="primary">
              Save
            </Button>}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default PuForm;
