import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../../core/common/base-query";

import { IPutData } from "../../../../core/interfaces";
import ApiPaths from "../../../../utils/ApiPaths";




export const demandMatchApi = createApi({
  reducerPath: "demandMatchApi",
  baseQuery: baseQuery,
  keepUnusedDataFor: 0.001,
  endpoints: (builder) => ({
    getCandidatesBySkills: builder.query<any, any>({
      query: (q) => `${ApiPaths.candidates}?q=${q}`,
    }),
    assignCanditates: builder.mutation<any, any>({
      query: ({body , id} : IPutData ) => ({
        url: `${ApiPaths.demandsCandidate}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getCandidatesByIds: builder.query<any, any>({
      query: (q) => `${ApiPaths.candidates}?q=${q}`,
    }),
  }),
});

export const recruitersAssignApi = createApi({
  reducerPath: "recruitersAssignApi",
  baseQuery: baseQuery,
  keepUnusedDataFor: 0.001,
  endpoints: (builder) => ({
    getRecruiters: builder.query<any, any>({
      query: (q) => `${ApiPaths.platformUser}?q=${q}`,
    }),
    assignRecruiters: builder.mutation<any, any>({
      query: ({body , id} : IPutData ) => ({
        url: `${ApiPaths.assignRecruiter}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getCandidatesByIds: builder.query<any, any>({
      query: (q) => `${ApiPaths.candidates}?q=${q}`,
    }),
  }),
});


export const { useGetCandidatesBySkillsQuery, useAssignCanditatesMutation, useGetCandidatesByIdsQuery } = demandMatchApi;
export const { useGetRecruitersQuery, useAssignRecruitersMutation } = recruitersAssignApi;
