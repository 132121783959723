import React, { useState } from "react";
import { Input } from "reactstrap";
import { useAppDispatch } from "../../../store/hooks";
import { errorToast, warnToast } from "../../../store/toastSlice";
import ApiPaths from "../../../utils/ApiPaths";
import ApiService from "../../../utils/ApiService";
import { ActionComfirmation } from "../../../utils/AppUtils";
import LoadingIndicator from "../../common/loading";

export const damandStatus = ["","ACTIVE", "CLOSED", "DELIVERED", "NOT FULFILLED"];
function StatusAction(row: any) {
  const [currentStatus, setStatus] = useState(row.data.status);
  const [isFetching, setFetching] = useState(false);

  const assignHandler = async (event: any) => {
    const status = event.target.value;
    setFetching(true);
    let result = await ActionComfirmation();
    if (!result.value) {
      setFetching(false);
      return;
    }

    let res = ApiService.Patch(`${ApiPaths.demands}/${row.data._id}`, {
      status,
    });

    res
      .then((res: any) => {
        if (res.data.success) {
          setStatus(status);
          setFetching(false);
        } else {
          warnToast(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log("error ====> ", err.response);
        errorToast(err.response);
      });
  };

  return (
    <div>
      {isFetching ? (
        <div style={{ width: 25 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <Input
          name="status"
          type="select"
          onChange={assignHandler}
          defaultValue={currentStatus??""}
        >
          {damandStatus.map((e: string) => (
            <option key={e}>{e}</option>
          ))}
        </Input>
      )}
    </div>
  );
}

export default StatusAction;
