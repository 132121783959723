export const Configs = {
  regions: [
    { name: "NORTH" },
    { name: "SOUTH" },
    { name: "EAST" },
    { name: "WEST" },
  ],
  roles: [
    { name: "RECRUITER_LEAD" },
    { name: "RECRUITER" },
    { name: "RECRUITER_HEAD" },
    { name: "SALES_HEAD" },
    { name: "POWER_NETWORKER" },
  ],
  classifications: [
    { name: "STANDARD" },
    { name: "SILVER" },
    { name: "GOLD" },
    { name: "PLATINUM" },
    { name: "DIAMOND" },
  ],
};
