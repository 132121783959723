import React from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { AppPaths } from "../../core/navigation/app-paths";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

interface IBreadcrumb {
  title: String;
  parent: String;
}

const Breadcrumb = ({ title, parent }: IBreadcrumb) => {
  const { userInfo } = useAppSelector((state: RootState) => state.usersSlice);


  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <div className="page-header-left">
              <h3>
                {title}
                <small>MentoScale {userInfo.role.replace("_", " ")}</small>
              </h3>
            </div>
          </Col>
          <Col lg="6">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <Link to={AppPaths.dashboard.fullPath()}>
                  <Home />
                </Link>
              </li>
              <li className="breadcrumb-item">{parent}</li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumb;
