import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConfigs } from "../../AppConfigs";
import { ApiHeaders } from "../../utils/ApiService";



export const baseQuery = fetchBaseQuery({
    baseUrl: AppConfigs().backEndHost,
    prepareHeaders: (headers) => {
      headers.set("authorization", ApiHeaders().Authorization);
      return headers;
    },
  });