import React, { Fragment, useEffect, useState } from "react";
import UserPanel from "./user-panel";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../core/navigation/menu";

// image import
import logo from "../../../assets/images/MentoScale.jpg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { DynamicKeyArray, IMenuItem } from "../../../core/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setRoles, setSeletedMenu } from "../../../store/appStateSlice";

const Sidebar = () => {
  let appDispatch = useAppDispatch();
  const appMenu: IMenuItem[] = useSelector(
    (state: RootState) => state.appState.menu
  );
  const { access : userAccess } = useSelector(
    (state: RootState) => state.usersSlice.userInfo
  );

  const [getMainMenu, setMainMenu] = useState<IMenuItem[]>(appMenu);
  const [active, setActive] = useState(false);
  let _mainMenu: IMenuItem[] = JSON.parse(JSON.stringify(getMainMenu));

  useEffect(() => {
    const currentUrl = window.location.pathname;

    _mainMenu.map((items) => {
      mainMenu.filter((Items: any) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems: any) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems: any) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    return () => {
      setMainMenu(appMenu);
    };
  }, [appMenu]);

  const setNavActive = (item: IMenuItem) => {
    setActive(!active);
    _mainMenu.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems: any) => {
          if (submenuItems !== item) {
            submenuItems.active = false;
          }
          if (submenuItems.children) {
            submenuItems.children.map(
              (childItem: any) => (childItem.active = false)
            );
            if (submenuItems.children.includes(item)) {
              submenuItems.active = true;
              menuItem.active = true;
            }
          }
          return false;
        });
      }
      return false;
    });
  
    item.active = !item.active;
    appDispatch(setSeletedMenu(item.menu_id));
    setMainMenu(_mainMenu);
  };

  const mainMenu = _mainMenu.map((menuItem: IMenuItem, i) => {
    if (!userAccess[menuItem.menu_id]) return <></>;
    return (
      <li className={`${menuItem.active ? "active" : ""}`} key={i}>
        {menuItem.sidebartitle ? (
          <div className="sidebar-title">{menuItem.sidebartitle}</div>
        ) : (
          ""
        )}
        {menuItem.type === "sub" ? (
          <a className="sidebar-header " onClick={() => setNavActive(menuItem)}>
            <i className={`${menuItem.icon}`}></i>
            <span>{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right"></i>
          </a>
        ) : (
          ""
        )}
        {menuItem.type === "link" ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? "active" : ""}`}
            onClick={() => setNavActive(menuItem)}
          >
            <i className={`${menuItem.icon}`}></i>
            <span>{menuItem.title}</span>
            {menuItem.children ? (
              <i className="fa fa-angle-right pull-right"></i>
            ) : (
              ""
            )}
          </Link>
        ) : (
          ""
        )}
        {menuItem.children ? (
          !userAccess[menuItem.menu_id] ? (
            <></>
          ) : (
            <ul
              className={`sidebar-submenu ${
                menuItem.active ? "menu-open" : ""
              }`}
              style={
                menuItem.active
                  ? { opacity: 1, transition: "opacity 500ms ease-in" }
                  : {}
              }
            >
              {menuItem.children.map((childrenItem: IMenuItem, index: number) =>
                !!!userAccess[childrenItem.menu_id] ? (
                  <></>
                ) : (
                  <li
                    key={index}
                    className={
                      childrenItem.children
                        ? childrenItem.active
                          ? "active"
                          : ""
                        : ""
                    }
                  >
                    {childrenItem.type === "sub" ? (
                      <a
                        href="#javaScript"
                        onClick={() => setNavActive(childrenItem)}
                      >
                        <i className="fa fa-circle"></i>
                        {childrenItem.title}{" "}
                        <i className="fa fa-angle-right pull-right"></i>
                      </a>
                    ) : (
                      ""
                    )}

                    {childrenItem.type === "link" ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                        className={childrenItem.active ? "active" : ""}
                        onClick={() => setNavActive(childrenItem)}
                      >
                        <i className="fa fa-circle"></i>
                        {childrenItem.title}{" "}
                      </Link>
                    ) : (
                      ""
                    )}
                    {childrenItem.children ? (
                      <ul
                        className={`sidebar-submenu ${
                          childrenItem.active ? "menu-open" : "active"
                        }`}
                      >
                        {childrenItem.children.map(
                          (childrenSubItem: any, key: any) => (
                            <li
                              className={childrenSubItem.active ? "active" : ""}
                              key={key}
                            >
                              {childrenSubItem.type === "link" ? (
                                <Link
                                  to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                  onClick={() => setNavActive(childrenSubItem)}
                                >
                                  <i className="fa fa-circle"></i>
                                  {childrenSubItem.title}
                                </Link>
                              ) : (
                                ""
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                )
              )}
            </ul>
          )
        ) : (
          ""
        )}
      </li>
    );
  });

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img
                className="blur-up lazyloaded"
                src={logo}
                alt=""
                height="50px"
              />
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />
          <ul className="sidebar-menu">{mainMenu}</ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
