import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
//images import
import man from "../../../assets/images/dashboard/man.png";
import { AppPaths } from "../../../core/navigation/app-paths";
import { setLoggedInState } from "../../../store/appStateSlice";
import { useAppDispatch } from "../../../store/hooks";
import { removeUserInfo } from "../../auth/features/user-slice";

const UserMenu = () => {
	const dispatch = useAppDispatch()
	const history = useNavigate();
	const logoutHandler = () => {
		reactLocalStorage.clear();
		dispatch(removeUserInfo())
		dispatch(setLoggedInState(false))
		history(AppPaths.aulogin.fullPath());
	}
	const hardRefresh = () => {
		
	}
	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media align-items-center">
					<img
						className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
						src={man}
						alt="header-user"
					/>
					<div className="dotted-animation">
						<span className="animate-circle"></span>
						<span className="main-circle"></span>
					</div>
				</div>
				<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
					<li>
						<Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
							<i data-feather="user"></i>Edit Profile
						</Link>
					</li>
					<li>
						<a  onClick={() => hardRefresh()}>
							<i data-feather="mail"></i>Inbox
						</a>
					</li>
					<li>
						<a href="#javaScript">
							<i data-feather="lock"></i>Hard Refresh
						</a>
					</li>
					<li>
						<a href="#javaScript">
							<i data-feather="settings"></i>Settings
						</a>
					</li>
					<li onClick={() => logoutHandler()}>
						
							<i data-feather="log-out"></i>Logout
						
					</li>
				</ul>
			</li>
		</Fragment>
	);
};

export default UserMenu;
