import { createSlice } from "@reduxjs/toolkit";
import { IQuestions } from "./questions-interface";
export interface IQuestionsState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  isBulkOpen: boolean;
  dataList: IQuestions[];
  editableData: IQuestions;
}

const inialState: IQuestionsState = {
  isEmpty: false,
  isOpen: false,
  isBulkOpen: false,
  message: "loading",
  dataList: [] as IQuestions[],
  editableData: {} as IQuestions,
};

export const QuestionsSlice = createSlice({
  name: "questions_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IQuestions;
      state.isOpen = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      if (action.payload.length == 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
      state.dataList = action.payload;
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setBulkOpen: (state, action) => {
      state.isBulkOpen = action.payload;
    },
  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setBulkOpen,
} = QuestionsSlice.actions;

export default QuestionsSlice.reducer;
