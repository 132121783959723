
const ApiPaths = {
    //Products Api
    products : "/product",
    productList : "/product-list",

    //Categories
    categories : "/category",
    categoryList : "/category-list",

    //Colleges
    colleges : "/colleges",
    collegesBulk: "/colleges/bulk-add",


    //Degree
    degrees : "/degree",
    degreeBulk: "/degree/bulk-add",


    //Order
    ordersList : "/orders",
    userList: "/users",

    //question
    questions: "/questions",
    questionsBulk: "/questions/bulk-add",

    //Dashboard
    dashboard : "/dashboard",

    //Platform User
    platformUser: "/puser/",
    platformUserApprove: "/puser/approve/",
    platformUserLogin: "/puser/login",
    platformAddUser: "/puser/add-user",
    platformUserResetPassword: "/puser/reset-password",
    platformUserUpdatePassword: "/puser/update-password",

    //App Paths
    appConfiguration: "/app-configuration",
    appConfigRole: "/app-configuration/role",

    //demands
    demands: '/demands',
    demandsCandidate: '/demands-candidate',
    assignRecruiter: '/assign-recruiters',
    demandsCandidateCanAssignMore: '/demands-candidate/can-assign-more',
    
    //candidates
    candidates: '/candidates',
    candidatesStatus: '/candidates-status',

    //accounts
    accounts: '/accounts',
    assignAccountRecruiters : '/account-recruiters'
    
}

export const ConfigIds =  {
    menuId : "62ac2d3868871d034393c075",
    appConfigId:  "62ac2e5f68871d03439501d2",
}

export default  ApiPaths;