import React, { useEffect, useState } from "react";

// imgaes import
import user from "../../assets/images/dashboard/user.png";
import user1 from "../../assets/images/dashboard/user1.jpg";
import user2 from "../../assets/images/dashboard/user2.jpg";
import user3 from "../../assets/images/dashboard/user3.jpg";
import man from "../../assets/images/dashboard/man.png";
import user5 from "../../assets/images/dashboard/user5.jpg";
import designer from "../../assets/images/dashboard/designer.jpg";
import ApiPaths from "../../utils/ApiPaths";
import ApiService from "../../utils/ApiService";
import { IUserState } from "../../core/interfaces";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import { successToast } from "../../store/toastSlice";

const RightSidebar = () => {
  const [open, setOpen] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [fetchedList, setFetchedList] = useState<IUserState[]>([]);
  const [dataList, setDataList] = useState<IUserState[]>([]);
  const [isEmpty, setEmpty] = useState(false);
  const [message, setMessage] = useState("Loading");

  const fetchList = async (mounted: boolean) => {
    // + `?approved=${props.isApproved}&role=${props.role}`
    await ApiService.Get(ApiPaths.platformUser + `?approved=${true}&role=props.role`)
      .then((res) => {
        if (mounted) {
          if (res.data.status == "Ok") {
            setFetchedList(res.data.data);
			setDataList(res.data.data);
            console.log(res);
          } else {
            setMessage(res.data.msg);
            setEmpty(true);
          }
        }
      })
      .catch((err) => {
        setEmpty(true);
        setMessage("Connection Error");
		
      });
  };

  useEffect(() => {
    setFetchedList([]);
    setDataList([]);
    let mounted = true;
    fetchList(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  const approveHandler = (data: IUserState, action: boolean) => {
	
    ApiService.Get(
      `${ApiPaths.platformUserApprove}${data._id}?approved=${action}`
    )
      .then((res) => {
        successToast(res.data.message);
        fetchList(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  let ApproveActions = (data : any) => {

    return (
      <>
        <ButtonGroup>
          <Button  onClick={() => approveHandler(data.row, true)} color="primary" style={{padding:'5px'}} outline>
		 <i
            className="fa fa-check"
			color="primary"
            style={{
              width: 15,
              fontSize: 15,
			  paddingRight: 5,
            }}
          />Approve </Button>
		  <Button onClick={() => approveHandler(data.row, false)} color="danger" style={{padding:'5px'}} outline>
		  <i
            className="fa fa-times"
			color="danger"
            style={{
              width: 15,
              fontSize: 15,
			  paddingRight: 5,
            }}
          />{' '}Reject </Button>
        </ButtonGroup>
        {/* <Col onClick={() => approveHandler(row, true)}>
          <i
            className="fa fa-check"
            style={{
              width: 35,
              fontSize: 15,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}
          >Approve</i>
        </Col>

        <Col onClick={() => approveHandler(row, false)}>
          <i
            className="fa fa-times"
            style={{
              width: 35,
              fontSize: 15,
              padding: 11,
              color: "#e4566e",
            }}
          >Reject</i>
        </Col> */}
      </>
    );
  };

  return (
    <div>
      <div className="right-sidebar" id="right_side_bar">
        <div>
          <div className="container p-0">
            <div className="modal-header p-l-20 p-r-20">
              <div className="col-sm-8 p-0">
                <h6 className="modal-title font-weight-bold">Approval List</h6>
              </div>
              <div className="col-sm-4 text-right p-0">
                <i className="mr-2" data-feather="settings"></i>
              </div>
            </div>
          </div>
          <div className="friend-list-search mt-0">
            <input type="text" placeholder="search user" 
			onChange={(e) => {
				var val: string = e.target.value.toLowerCase();
				var list = fetchedList.filter((item) => item.full_name.toLowerCase().includes(val))
				setDataList(list);
				
			}}
			/>
            <i className="fa fa-search"></i>
          </div>
          <div className="p-l-30 p-r-30">
            <div className="chat-box">
              <div className="people-list friend-list">
                <ul className="list">
                  {dataList.map((userItem) => {
                    return (
                      <li key={userItem._id} className="clearfix">
                        <Container>
                          <Row>
                            {" "}
                            <div className="about">
                              <div className="name">{userItem.full_name}</div>

							  <div style={{fontSize:12,paddingTop:'5px' }}>{userItem.role} - {userItem.region}</div>
							  <div style={{fontSize:12 }}> {userItem.username}</div>
                            </div>
                          </Row>
						 
                          <Row style={{paddingTop:'5px' }}>
							
                            <ApproveActions row={userItem}></ApproveActions>
                          </Row>
						  <hr></hr>
                        </Container>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
