import React, { useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import Login from "../../components/auth/login";
import Dashboard from "../../components/dashboard";
import NotFound from "../../components/common/not_found";

import { Route, Routes, useNavigate } from "react-router-dom";
import DashboardWrapper from "../../components/dashboard_wrapper/dashboar_wrapper";
import { AppPaths } from "./app-paths";
import PlatformUsers from "../../components/users/platform-users/platform-users";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { reactLocalStorage } from "reactjs-localstorage";
import { IUserState } from "../interfaces";
import ThankYou from "../../components/single_screens/thank_you";
import AccessController from "./access-controller";
import Profile from "../../components/settings/profile/profile";
import { MenuAssignment } from "../../components/settings/menu-assignent/menu-assignment";
import Demands from "../../components/demand/demands/demands";
import Colleges from "../../components/basic-details/colleges/colleges";
import Degrees from "../../components/basic-details/degree/degrees";
import Questions from "../../components/question/questions/questions";
import Candidates from "../../components/users/candidates/candidates";
import DemandsCandidates from "../../components/demand/demands-candidates/demands-candidates";
import { setUserInfo } from "../../components/auth/features/user-slice";
import DemandsRecruiters from "../../components/demand/demands-recruiters/demandsrecruiters";
import AccountRecruiters from "../../components/account/account-recruiters/account-recruiters";
import Accounts from "../../components/account/accounts/accounts";

function AppRoutes() {
  const {userInfo} = useSelector((state: RootState) => state.usersSlice);
  const dispatch = useDispatch();
  const history = useNavigate();
  
  useEffect(() => {
    if (!userInfo.role) {
      const userInfo = reactLocalStorage.getObject("userInfo") as IUserState;
      if (!userInfo.role) {
        history(AppPaths.aulogin.fullPath());
      }
      dispatch(setUserInfo(userInfo));
    }
    // setUpdated(true);
    history(AppPaths.dashboard.fullPath());
  }, []);

  const navigateTo = (screen: JSX.Element) => {
    return (
      <> 
      <AccessController>
        <DashboardWrapper>{screen}</DashboardWrapper>
      </AccessController>
       
      </>
    );
  };
  const fullPath = (path: string) => `${process.env.PUBLIC_URL}${path}`;
  return (
    <PerfectScrollbar>
      <Routes>
        <Route path={`/`} element={<Login />} />
        <Route path={fullPath(AppPaths.thankYou)} element={<ThankYou />} />
        <Route path={fullPath(AppPaths.aulogin)} element={<Login />} />
        <Route
          path={fullPath(AppPaths.dashboard)}
          element={navigateTo(<Dashboard />)}
        />
        <Route
          path={fullPath(AppPaths.bdCollesges)}
          element={navigateTo(<Colleges />)}
        />
        <Route
          path={fullPath(AppPaths.bdDegrees)}
          element={navigateTo(<Degrees />)}
        />
        <Route
          path={fullPath(AppPaths.amAssessment)}
          element={navigateTo(<Questions />)}
        />
        {/* <Route
          path={fullPath(AppPaths.amAnalytics)}
          element={navigateTo(<AdvanceAssessment />)}
        /> */}
        <Route
          path={fullPath(AppPaths.uCandidates)}
          element={navigateTo(<Candidates />)}
        />

        <Route
          path={fullPath(AppPaths.dDemands)}
          element={navigateTo(<Demands />)}
        />
        <Route
          path={fullPath(AppPaths.dDemandsCandidates)}
          element={navigateTo(<DemandsCandidates />)}
        />
        <Route
          path={fullPath(AppPaths.dDemandsRecruiters)}
          element={navigateTo(<DemandsRecruiters />)}
        />
        <Route
          path={fullPath(AppPaths.aAccounts)}
          element={navigateTo(<Accounts />)}
        />
        <Route
          path={fullPath(AppPaths.aAccountRecruiters)}
          element={navigateTo(<AccountRecruiters />)}
        />
        <Route
          path={fullPath(AppPaths.uPlatformUsers)}
          element={navigateTo(
            <PlatformUsers isApproved={true} page_id={"PLATFORM_USERS"} />
          )}
        />

        <Route
          path={fullPath(AppPaths.uforApproval)}
          element={navigateTo(
            <PlatformUsers isApproved={false} page_id={"FOR_APPROVAL"} />
          )}
        />

        <Route
          path={fullPath(AppPaths.uRecruiters)}
          element={navigateTo(
            <PlatformUsers
              isApproved={true}
              role={"RECRUITER_LEAD"}
              page_id="RECRUITERS"
            />
          )}
        />

        <Route
          path={fullPath(AppPaths.uSalesTeams)}
          element={navigateTo(
            <PlatformUsers
              isApproved={true}
              role={"SALES_HEAD"}
              page_id={"SALES_TEAM"}
            />
          )}
        />

        <Route
          path={fullPath(AppPaths.stProfile)}
          element={navigateTo(<Profile />)}
        />

        <Route
          path={fullPath(AppPaths.stMenuAssigment)}
          element={navigateTo(<MenuAssignment />)}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/*`}
          element={navigateTo(<NotFound />)}
        />
      </Routes>
    </PerfectScrollbar>
  );
}

export default AppRoutes;
