import { createSlice } from "@reduxjs/toolkit";
import { IUserState } from "../../../../core/interfaces";

export const PageDetails = (role: string) => {
  const roleConfig: IUserScreenConfig = {
    title: "Platform Users",
    role: role,
    isApproved: true,
  };
  switch (role) {
    case "RECRUITER_LEAD":
      return { ...roleConfig, title: "Lead Recruiters" };
    case "SALES_HEAD":
    default:
      return;
  }
};

export interface IUserScreenConfig {
  title: string;
  role: string;
  isApproved: boolean;
}

export enum actions {
  PU_OPEN,
  PU_EDITABLEDATA,
  PU_DATALIST,
  PU_EMPTY,
  PU_MESSAGE,
}

export interface IPuInitialState {
  isOpen: boolean;
  editableData: IUserState;
  dataList: any[];
  isEmpty: boolean;
  message: string;
  title: string;
  role: string;
  isApproved: boolean;
  isBulkOpen: boolean;
}

export const puInitialState: IPuInitialState = {
  isEmpty: false,
  isOpen: false,
  message: "loading",
  editableData: {} as IUserState,
  dataList: [],
  title: "Platform Users",
  role: "",
  isApproved: true,
  isBulkOpen: false,
};

// export function PuReducer(
//   state = puInitialState,
//   action: { type: actions; payload: any }
// ) {
//   // The reducer normally looks at the action type field to decide what happens
//   switch (action.type) {
//     case actions.PU_OPEN: {
//       return ({...state,...action.payload});
//     }
//     case actions.PU_EDITABLEDATA: {
//       return ({...state, ...action.payload});
//     }
//     case actions.PU_DATALIST: {
//       return ({...state, dataList: action.payload});
//     }
//     case actions.PU_EMPTY: {
//       return ({...state, isEmpty: action.payload});
//     }
//     case actions.PU_MESSAGE: {
//       return ({...state, message: action.payload});
//     }
//     default:
//       return state;
//   }
// }

export const platformUserSlice = createSlice({
  name: "platformUser_slice",
  initialState: puInitialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IUserState;
      state.isOpen = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
      if (action.payload.length === 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setBulkOpen: (state, action) => {
      state.isBulkOpen = action.payload;
    },
  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setBulkOpen,
} = platformUserSlice.actions;

export default platformUserSlice.reducer;
