import React, { Fragment, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Authorization } from "../../../core/authorization";
import { DynamicKeyArray, IUserState } from "../../../core/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { errorToast, successToast, warnToast } from "../../../store/toastSlice";
import ApiPaths from "../../../utils/ApiPaths";
import ApiService from "../../../utils/ApiService";
import { FormDataToJson } from "../../../utils/AppUtils";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import LoadingIndicator from "../../common/loading";
import PuForm from "./components/pu-form";
import {
  setDataList,
  setEditable,
  setEmpty,
  setOpen,
  setBulkOpen,
} from "./store/reducers";
function PlatformUsers(props: any) {
  const pathname = window.location.pathname;
  const appDispatch = useAppDispatch();

  const { editableData, dataList, isEmpty, message, isOpen, isBulkOpen } =
    useAppSelector((state) => state.platformUserSlice);
  const onOpenModal = () => appDispatch(setOpen(true));
  const onCloseModal = () => {
    appDispatch(setOpen(false));
  };

  const fetchList = async (mounted: boolean) => {
    appDispatch(setEmpty(false))
    await ApiService.Get(
      ApiPaths.platformUser + `?approved=${props.isApproved}&role=${props.role}`
    )
      .then((res) => {
        if (mounted) {
          if (res.data.status == "Ok") {
            appDispatch(setDataList(res.data.data))
          } else {
            errorToast(res.data.msg);
                appDispatch(setEmpty(true))
          }
        }
      })
      .catch((err) => {
        appDispatch(setEmpty(true))
        errorToast("Connection Error");
      });
  };

  useEffect(() => {
    appDispatch(setDataList([]))
    let mounted = true;
    fetchList(mounted);
    return () => {
      mounted = false;
    };
  }, [props.isApproved, pathname]);

  const onSubmitForm = async (event: {
    preventDefault: () => void;
    target: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const formData: any = FormDataToJson(new FormData(event.target));
    formData.terms_and_conditions = true;

    let res = editableData._id
      ? ApiService.Patch(
          `${ApiPaths.platformUser}${editableData._id}`,
          formData
        )
      : ApiService.Post(ApiPaths.platformAddUser, formData);

    res
      .then((res: any) => {
        if (res.data.success) {
          onCloseModal();
          appDispatch(setDataList([]))
          fetchList(true);
        } else {
          warnToast(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log("error ====> ", err.response);
      });
  };

  const handleDelete = (data: any) => {
    ApiService.Delete(`${ApiPaths.platformUser}${data._id}`)
      .then((res) => {
        successToast(res.data.message);
        appDispatch(setDataList([]));
        fetchList(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleEdit = (data: any) => {
    appDispatch(setEditable(data));
  };

  const approveHandler = (data: any, action: boolean) => {
    ApiService.Get(
      `${ApiPaths.platformUserApprove}${data._id}?approved=${action}`
    )
      .then((res) => {
        successToast(res.data.message);
            appDispatch(setDataList([]));
            fetchList(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  let approveActions = (row: any) => {
    row = row.data;
    return (
      <div>
        <span onClick={() => approveHandler(row, true)}>
          <i
            className="fa fa-check"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}
          ></i>
        </span>

        <span onClick={() => approveHandler(row, false)}>
          <i
            className="fa fa-times"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
            }}
          ></i>
        </span>
      </div>
    );
  };

  const title = props.page_id ? props.page_id.split("_").join(" ") : undefined;
  return (
    <Fragment>
      <Breadcrumb title={title ?? "Platform Users"} parent="users" />

      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="btn-popup pull-right">
                  {Authorization.canCreate() && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={onOpenModal}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      Add Platform User
                    </Button>
                  )}
                </div>
                <div className="btn-popup pull-right">
                  {isOpen && (
                    <>
                      <PuForm
                        editableData={editableData}
                        isOpen={isOpen}
                        onCloseHandler={onCloseModal}
                        onSubmitForm={onSubmitForm}
                      ></PuForm>
                    </>
                  )}
                </div>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  {!(dataList.length > 0) ? (
                    <LoadingIndicator isEmpty={isEmpty} message={message} />
                  ) : (
                    <Datatable
                        title="UserList"
                        myData={dataList}
                        multiSelectOption={false}
                        pageSize={10}
                        pagination={true}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        actionName={props.isApproved ? "Actions" : "Approve"}
                        customButtons={props.isApproved ? null : approveActions}                 />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
}

export default PlatformUsers;
