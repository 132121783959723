import { Fragment, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  errorToast,
  successToast,
  warnToast,
} from "../../../store/toastSlice";
import ApiPaths from "../../../utils/ApiPaths";
import ApiService from "../../../utils/ApiService";
import { FormDataToJson } from "../../../utils/AppUtils";

import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import LoadingIndicator from "../../common/loading";
import DemandsForm from "./components/demands-form";
import DemandsMatch from "./components/assign_candidates/demands-match";
import {
  setDataList,
  setEditable,
  setEmpty,
  setOpen,
  setSelected,
  setShowAssignRecruiters,
  setShowMatch,
} from "./features/demands-slice";
import {
  authAssignRecruiter,
  authCreate,
  authMatchDemand,
  authUpdate,
} from "../../../core/authorization";
import AssignRecruiters from "./components/assign_recruiters/assign-recruiters";
import { IActionColumns } from "../../../core/interfaces";
import StatusAction from "./status-action";

function Demands() {
  const appDispatch = useAppDispatch();
  const {
    editableData,
    dataList,
    isEmpty,
    message,
    isOpen,
    selectedRow,
    isShowMatch,
    isShowAssignRecruiters,
  } = useAppSelector((state) => state.demandsSlice);
  const onOpenModal = () => appDispatch(setOpen(true));
  const onCloseModal = () => {
    appDispatch(setOpen(false));
  };

  const fetchList = async (mounted: boolean) => {
    appDispatch(setEmpty(false))
    await ApiService.Get(
      ApiPaths.demands //+ ?approved=${props.isApproved}&role=${props.role}
    )
      .then((res) => {
        if (mounted) {
          if (res.data.status == "Ok") {
            appDispatch(setDataList(res.data.data));
          } else {
            errorToast(res.data.msg);
            appDispatch(setEmpty(true));
          }
        }
      })
      .catch((err) => {
        appDispatch(setEmpty(true));
        errorToast("Connection Error");
      });
  };

  useEffect(() => {
    appDispatch(setDataList([]));
    let mounted = true;
    fetchList(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  const onSubmitForm = async (event: {
    preventDefault: () => void;
    target: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const formData: any = FormDataToJson(new FormData(event.target));

    let res = editableData._id
      ? ApiService.Patch(`${ApiPaths.demands}/${editableData._id}`, formData)
      : ApiService.Post(ApiPaths.demands, formData);

    res
      .then((res: any) => {
        if (res.data.success) {
          onCloseModal();
          appDispatch(setDataList([]));
          fetchList(true);
        } else {
          warnToast(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log("error ====> ", err.response);
      });
  };

  const handleDelete = (data: any) => {
    ApiService.Delete(`${ApiPaths.demands}/${data._id}`)
      .then((res) => {
        successToast(res.data.message);
        appDispatch(setDataList([]));
        fetchList(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleEdit = (data: any) => {
    appDispatch(setEditable(data));
  };

  const handleSelection = (data: any) => {
    appDispatch(setSelected(data));
  };


  const actionColumns : IActionColumns[] =  [
      {
        columnName : 'status',
        customAction : StatusAction,
        isActive : authUpdate.hasAccess(),
      }
  ]

  return (
    <Fragment>
      <Breadcrumb title={"Demands"} parent="demand" />

      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {authMatchDemand.hasAccess() && selectedRow._id && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => appDispatch(setShowMatch(true))}
                    data-toggle="modal"
                    data-original-title="test"
                    data-target="#exampleModal"
                  >
                    Assign Candidates
                  </Button>
                )}{" "}
                {authAssignRecruiter.hasAccess() && selectedRow._id && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => appDispatch(setShowAssignRecruiters(true))}
                    data-toggle="modal"
                    data-original-title="test"
                    data-target="#exampleModal"
                  >
                    Assign Recruiters
                  </Button>
                )}
                <div className="btn-popup pull-right">
                  {authCreate.hasAccess() && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={onOpenModal}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      Add Demands
                    </Button>
                  )}
                </div>
                <div className="btn-popup pull-right">
                  {isOpen && (
                    <>
                      <DemandsForm
                        editableData={editableData}
                        isOpen={isOpen}
                        onCloseHandler={onCloseModal}
                        onSubmitForm={onSubmitForm}
                      ></DemandsForm>
                    </>
                  )}

                  {isShowMatch && (
                    <>
                      <DemandsMatch
                        editableData={selectedRow}
                        isOpen={isShowMatch}
                        onCloseHandler={onCloseModal}
                        onSubmitForm={onSubmitForm}
                      ></DemandsMatch>
                    </>
                  )}

                  {isShowAssignRecruiters && (
                    <>
                      <AssignRecruiters
                        editableData={selectedRow}
                        isOpen={isShowAssignRecruiters}
                        onCloseHandler={() => appDispatch(setOpen(false))}
                        onSubmitForm={onSubmitForm}
                      ></AssignRecruiters>
                    </>
                  )}
                </div>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  {!(dataList.length > 0) ? (
                    <LoadingIndicator isEmpty={isEmpty} message={message} />
                  ) : (
                    <Datatable
                      onSelected={
                        authMatchDemand.hasAccess() && handleSelection
                      }
                      title="UserList"
                      myData={dataList}
                      multiSelectOption={false}
                      pageSize={10}
                      pagination={true}
                      onEdit={handleEdit}
                      columnNames={[
                        "status",
                        "account_name",
                        "jd_code",
                        "jd_title",
                        "skill_category",
                        "skill",
                        "experience",
                        "notice_period",
                        "budget",
                        "location",
                        "spoc_recruiter",
                        "mentoscale_lr_name",
                        "mentoscale_lr_email",
                        "_id",
                        "spoc_recruiter_head",
                        "spoc_recruiter_head_email",
                        "account_manager",
                        "delivery_head",
                        "client",
                        "date",
                        "jso_number",
                        "domain",
                        "platform",
                        "module",
                        "comments",
                        "is_active",
                        "createdAt",
                        "updatedAt",
                        "__v",
                        "assigned_candidates",
                        "updated_by",
                        "assigned_recruiter_id",
                        "assigned_recruiter_name",
                      ]}
                      actionColumns={actionColumns}
                      onDelete={handleDelete}
                      actionName={"Actions"}
                      selectionColumn={"jd_code"}
                      hideColumns={["assigned_candidates"]}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
}

export default Demands;
