import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { showToast } from "../../../store/toastSlice";
import ApiPaths from "../../../utils/ApiPaths";
import ApiService from "../../../utils/ApiService";
import { FormDataToJson } from "../../../utils/AppUtils";
import LoadingIndicator from "../../common/loading";
import { setOpen } from "../features/user-slice";

const ForgotForm = (props: any) => {
  const userState = useSelector((state: RootState) => state.usersSlice);
  const appDispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    event.preventDefault();
    const formData: any = FormDataToJson(new FormData(event.target));
    setLoading(true);
    ApiService.Post(`${ApiPaths.platformUserResetPassword}`, formData)
      .then((res: any) => {
        if (res.data.success) {
          setLoading(false);
          appDispatch(setOpen(false));
          Swal.fire(
            "Password Reset Successfull",
            "Please Check Mail for latest Password"
          );
        } else {
          setLoading(false);
          Swal.fire("Password Reset Failure", res.data.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        showToast('Something Went Wrong')
        console.log("error ====> ", err.response);
      });
  };

  return (
    <Modal isOpen={userState.isOpen} backdrop="static">
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Reset Password
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup>
            <Input
              required
              name="username"
              type="email"
              className="form-control"
              placeholder="Username"
              id="exampleInputEmail1"
            />
          </FormGroup>

          <ModalFooter>
            {isLoading ? (
                <div style={{ width: 35 }}>
              <LoadingIndicator /></div>
            ) : (
              <Button type="submit" color="primary">
                Reset
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                appDispatch(setOpen(false));
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ForgotForm;
