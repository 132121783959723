import _ from "lodash";
import { useDispatch } from "react-redux";
import { IRoles } from "../../../../core/interfaces";
import { setRoles } from "../../../../store/appStateSlice";
import { warnToast } from "../../../../store/toastSlice";
import ApiPaths from "../../../../utils/ApiPaths";
import ApiService from "../../../../utils/ApiService";
import { FormDataToJson } from "../../../../utils/AppUtils";

const fetchRoles = () => {};

const addInObject = (item: any, key: any, value: any) => {
  if (!!item.access[key]) {
    item.access[key].push(value);
  } else {
    (item.access as Record<string, typeof value>)[`${key}`] = value;
  }
};

const onCheckedHandler = (
  roles: IRoles[],
  updateRoles: any,
  isChecked: Boolean,
  menuId: string,
  role: string,
  children?: string[]
) => {
  let lRoles: IRoles[] = [...roles];
  updateRoles(
    lRoles.map((item: IRoles) => {
      if (item.role_id == role) {
        if (menuId.includes("__CRUD__")) {
          var crud = menuId.split("__CRUD__");
          if (isChecked) {
            addInObject(item, crud[0], crud[1]);
          } else {
            _.remove(item.access[crud[0]], (curMenu) => curMenu === crud[1]);
          }
        } else {
          if (!isChecked) {
            delete item.access[menuId];
          } else {
            addInObject(item, menuId, []);
          }
        }
      }
      return item;
    })
  );
};

const onSelectorHandler = (
  isChecked: boolean,
  role: string,
  roles: any,
  updateRoles: any
) => {

  
  let lRoles = [...roles];
  updateRoles([
    ...lRoles.map((item) =>
      item.role_id == role ? { ...item, is_checked: isChecked } : item
    ),
  ]);
};

const fetechRoles = (dispatch: any) => {
 
  let res = ApiService.Get(ApiPaths.appConfigRole);
  res
    .then((res: any) => {
      if (res.data.success) {
        console.log(res.data);
        dispatch(setRoles(res.data.data));
      } else {
        warnToast(res.data.message);
      }
    })
    .catch((err: any) => {
      console.log("error ====> ", err.response);
    });
};

const updateHandler = (name: string, roles: IRoles[], setOpen: any) => {
  let roleInfo = roles.find((item) => item.role_id == name);

  let res = ApiService.Patch(ApiPaths.appConfigRole, roleInfo);
  res
    .then((res: any) => {
      if (res.data.success) {
        onCloseModal(setOpen);
      } else {
        warnToast(res.data.message);
      }
    })
    .catch((err: any) => {
      console.log("error ====> ", err.response);
    });
};

const onOpenModal = (setOpen: any) => {
  setOpen(true);
};

const onCloseModal = (setOpen: any) => {
  setOpen(false);
};

const onSubmitForm = async (
  setOpen: any,
  event: {
    preventDefault: () => void;
    target: HTMLFormElement | undefined;
  }
) => {
  event.preventDefault();
  const formData: any = FormDataToJson(new FormData(event.target));

  let res = ApiService.Post(ApiPaths.appConfigRole, formData);

  res
    .then((res: any) => {
      if (res.data.success) {
        onCloseModal(setOpen);
      } else {
        warnToast(res.data.message);
      }
    })
    .catch((err: any) => {
      console.log("error ====> ", err.response);
    });
};

export {
  fetchRoles,
  onSelectorHandler,
  onSubmitForm,
  onOpenModal,
  onCloseModal,
  onCheckedHandler,
  updateHandler,
  fetechRoles
};
    
