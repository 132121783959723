import React, { useEffect } from "react";
import { data } from "../../../../../assets/data/category";
import { authAssignRecruiter } from "../../../../../core/authorization";
import { IUser } from "../../../../../core/interfaces";
import { useAppSelector } from "../../../../../store/hooks";
import Datatable from "../../../../common/datatable";
import LoadingIndicator from "../../../../common/loading";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import { useGetRecruitersQuery } from "../../features/accounts-query";
import { ActionButtons } from "./assign-buttons";

function AssignableRecruiters() {
  const { selectedRow, recruiterQuery } = useAppSelector((state) => state.accountSlice);
  const region = useAppSelector((state) => state.usersSlice.userInfo.region);
  const { refetch, isFetching, data } = useGetRecruitersQuery(
    JSON.stringify({...recruiterQuery,region})
  );

  useEffect(() => {
    refetch();
  }, [selectedRow])
  
  return (
    <>
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <>
          <Datatable
            height={350}
            customButtons={ActionButtons}
            quikFilter={false}
            quikExport={false}
            actionName={"View"}
            columnNames={["full_name","username"]}
            isDefaultShowActions={authAssignRecruiter.hasAccess()}
            myData={data.data.filter(
              (e: IUser) =>
                selectedRow.assigned_recruiter_id != e._id
            )}
          />
        </>
      )}
    </>
  );
}

export default AssignableRecruiters;
