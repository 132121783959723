import React, { useState } from "react";
import { Button, Card, CardTitle, Col, Input } from "reactstrap";
import { DynamicKeyArray, IMenuItem } from "../../../../core/interfaces";
import "./menu-container.scss";

type Props = {
  menuItem: IMenuItem;
  disabled: boolean;
  onChanged: any;
  level: number;
  parent: string;
  selectedMenu: DynamicKeyArray;
  crud: string[];
};

const MenuContainer: React.FC<Props> = ({
  menuItem,
  children,
  disabled,
  onChanged,
  level,
  parent,
  selectedMenu,
  crud,
}) => {
  const [isEnabled, setEnabled] = useState(disabled);
  const defaultMenu: string[] = [];//["DASHBOARD", "LOGOUT", "SETTINGS", "PROFILE"];
  const extraCrud: any = {
    'DEMANDS' : ['MATCH_DEMAND','ASSIGN_RECRUITER'],
    'ACCOUNTS' : ['ASSIGN_RECRUITER'],
    'FOR_APPROVAL' : ['APPROVE/REJECT'],
    'DEMANDS_CANDIDATES': ['APPROVE/REJECT']
  };
  return (
    <li>
      <a className="sidebar-header ">
        <Input
          className="checkbox_animated"
          id={`${parent}`}
          name={menuItem.menu_id}
          type="checkbox"
          disabled={defaultMenu.includes(menuItem.menu_id) ?? disabled}
          onChange={(e) => {
            onChanged(e);
            if (level < 2) setEnabled(e.target.checked);
          }}
          defaultChecked={
            !!selectedMenu[menuItem.menu_id]
          //   [...selectedMenu, ...defaultMenu].includes(
          //   menuItem.menu_id
          // )
        }

          // {defaultMenu.includes(menuItem.menu_id) == ?? false}
        />{" "}
        <span>{menuItem.title}</span>
      </a>
      {(isEnabled ||!!selectedMenu[menuItem.menu_id])
        // [...selectedMenu, ...defaultMenu].includes(menuItem.menu_id)) 
        &&
        menuItem.children && (
          <ul key={`${parent}-${menuItem.menu_id}-1-${menuItem.menu_id}`}>
            {menuItem.children.map((item: IMenuItem, index: number) => {
              return (
                <MenuContainer
                  crud={crud}
                  key={`${parent}__${item.menu_id}`}
                  parent={`${parent}__${item.menu_id}`}
                  onChanged={onChanged}
                  selectedMenu={selectedMenu}
                  menuItem={item}
                  disabled={!isEnabled}
                  level={1}
                />
              );
            })}
          </ul>
        )}
      {(level == 1 && isEnabled)&& (
        <div  className="crud-holder-parent">
          {[...crud, ...(extraCrud[menuItem.menu_id]||[])].map((item) => (
            <span className="crud-holder">
              <Input
                className="crud-checkbox"
                id={`${parent}__${item}`}
                name={`${menuItem.menu_id}__CRUD__${item}`}
                type="checkbox"
                disabled={defaultMenu.includes(menuItem.menu_id) ?? disabled}
                onChange={(e) => {
                  onChanged(e);
                  // if (level < 1) setEnabled(e.target.checked);
                }}
                defaultChecked={ selectedMenu[menuItem.menu_id] &&
                  selectedMenu[menuItem.menu_id].includes(item)
                //   [...defaultMenu].includes(
                //   menuItem.menu_id
                // )
              }
              />{" "}
              <span>{item}</span>
            </span>
          ))}
        </div>
      )}
    </li>
  );
};

export default MenuContainer;

type ICCprops = {
  title: string;
  onUpdateClick: any;
};
export const ContainerCard: React.FC<ICCprops> = ({
  title,
  children,
  onUpdateClick,
}) => {
  return (
    <>
      <Col lg="3">
        <Card>
          <CardTitle className="ma-card-title align-self-center">
            {title}
          </CardTitle>
          <hr className="title-hr" />
          <div className="menu-assigment">
            {/* <div className="sidebar custom-scrollbar"> */}
            <ul className="sidebar-menu">{children}</ul>
            {/* </div> */}
          </div>
          <div className="align-self-center align-items-center ma-card-title">
            <Button
              type="button"
              color="primary"
              data-toggle="modal"
              data-original-title="test"
              data-target="#exampleModal"
              onClick={onUpdateClick}
            >
              UPDATE
            </Button>
          </div>
        </Card>
      </Col>
    </>
  );
};

/**

DASHBOARD

BASIC_DETAILS
COLLEGES
DEGREE_&_COURSES
ACADEMIC_SCORE
ASSESMENT
ASSESSMENTS
ANALYTICS
MEDIA
USERS
FOR_APPROVAL
CANDIDATES
RECRUITERS
SALES_TEAM
ACCOUNTS
PLATFORM_USERS
REPORTS
SETTINGS
PROFILE
MENU_ASSIGNMENT
LOGOUT
 */
