
  import React, { FC, useState } from "react";
  import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    ModalFooter,
    Button,
  } from "reactstrap";
  import LoadingIndicator from "../../common/loading";
  import { IDegrees } from "./degrees-interface";

const DegreesForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true)
    props.onSubmitForm(event);
    setLoading(false)
  };

  const defValue: IDegrees = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler}
    backdrop="static"
    >
      <ModalHeader //  toggle={props.onCloseHandler}
      
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Degrees
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
        <FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Degree Name:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.degree_name}
                className="form-control mb-0"
                name="degree_name"
                id="degree_name-text"
                type="text"
                required
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Course:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.course}
                className="form-control mb-0"
                name="course"
                id="course-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Description:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.description}
                className="form-control mb-0"
                name="description"
                id="description-textarea"
                type="textarea"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group row">
    <Label className="col-xl-3 col-sm-4 mb-0">
        Select Status:
    </Label>
    <div className="col-xl-8 col-sm-7">
        <Input 
        defaultValue={defValue.status}
        type="select" name="status" id="status-String">
        <option value="ACTIVE">Active</option><option value="INACTIVE">Inactive</option>
        </Input>
    </div>
</FormGroup>
          <ModalFooter>
         { isLoading ? 
         <LoadingIndicator/>
        :
            <Button type="submit" color="primary">
              Save
            </Button>}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DegreesForm;

    