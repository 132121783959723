import React, { useState } from "react";

import { User, Settings, Key } from "react-feather";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { Configs } from "../../../assets/data/configs";
import { IUserState } from "../../../core/interfaces";
import ResetPassword from "./components/reset-password";

const TabsetProfile = (props : { userDetails : IUserState}) => {
	const [activeTab, setActiveTab] = useState(1);

 
  return (
    <div>
      <Nav tabs className="nav nav-tabs tab-coupon">
        <NavItem>
          <NavLink
            className={activeTab == 1 ? "active" : ""}
            onClick={() => setActiveTab(1)}
          >
            <User className="mr-2" />
            Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == 2 ? "active" : ""}
            onClick={() => setActiveTab(2)}
          >
            <Settings className="mr-2" />
            Settings
          </NavLink>
          
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == 3 ? "active" : ""}
            onClick={() => setActiveTab(3)}
          >
            <Key className="mr-2" />
            Reset Password
          </NavLink>
          
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <div className="tab-pane fade show active">
            <h5 className="f-w-600 f-16">Profile</h5>
            <div className="table-responsive profile-table">
              <Table className="table-responsive">
                <tbody>
                  <tr>
                    <td>Full Name:</td>
                    <td>{props.userDetails.full_name}</td>
                  </tr>
                  
                  <tr>
                    <td>Email:</td>
                    <td>{props.userDetails.username}</td>
                  </tr>
                  <tr>
                    <td>Gender:</td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td>Mobile Number:</td>
                    <td>{props.userDetails.mobile_no}</td>
                  </tr>
                  <tr>
                    <td>DOB:</td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td>Region:</td>
                    <td>{props.userDetails.region}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={2}>
          {/* <div className="tab-pane fade"> */}
          <div className="account-setting">
            <h5 className="f-w-600 f-16">Notifications</h5>
            <Row>
              <Col>
                <Label className="d-block">
                  <Input
                    className="checkbox_animated"
                    id="chk-ani"
                    type="checkbox"
                    defaultChecked
                  />
                  Allow Desktop Notifications
                </Label>
                <Label className="d-block">
                  <Input
                    className="checkbox_animated"
                    id="chk-ani1"
                    type="checkbox"
                  />
                  Enable Notifications
                </Label>
                <Label className="d-block">
                  <Input
                    className="checkbox_animated"
                    id="chk-ani2"
                    type="checkbox"
                  />
                  Get notification for my own activity
                </Label>
                <Label className="d-block mb-0">
                  <Input
                    className="checkbox_animated"
                    id="chk-ani3"
                    type="checkbox"
                    defaultChecked
                  />
                  DND
                </Label>
              </Col>
			  
            </Row>
			<br/>
			<Button type="button" color="primary">
              Update Notifications
            </Button>
          </div>
          {/* <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Deactivate Account</h5>
            <Row>
              <Col>
                <Label className="d-block">
                  <Input
                    className="radio_animated"
                    id="edo-ani"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                  />
                  I have a privacy concern
                </Label>
                <Label className="d-block">
                  <Input
                    className="radio_animated"
                    id="edo-ani1"
                    type="radio"
                    name="rdo-ani"
                  />
                  This is temporary
                </Label>
                <Label className="d-block mb-0">
                  <Input
                    className="radio_animated"
                    id="edo-ani2"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                  />
                  Other
                </Label>
              </Col>
            </Row>
            <Button type="button" color="primary">
              Deactivate Account
            </Button>
          </div>
          <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Delete Account</h5>
            <Row>
              <Col>
                <Label className="d-block">
                  <Input
                    className="radio_animated"
                    id="edo-ani3"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  No longer usable
                </Label>
                <Label className="d-block">
                  <Input
                    className="radio_animated"
                    id="edo-ani4"
                    type="radio"
                    name="rdo-ani1"
                  />
                  Want to switch on other account
                </Label>
                <Label className="d-block mb-0">
                  <Input
                    className="radio_animated"
                    id="edo-ani5"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  Other
                </Label>
              </Col>
            </Row>
            <Button type="button" color="primary">
              Delete Account
            </Button>
          </div> */}
          {/* </div> */}
        </TabPane>
        <TabPane tabId={3}>
          <ResetPassword/>
          </TabPane>
      </TabContent>
    </div>
  );
};

export default TabsetProfile;
