import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import LoadingIndicator from "../../../common/loading";
import { IDemandsCandidates } from "./../features/demandscandidates-interface";

const DemandsCandidatesForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true);
    props.onSubmitForm(event);
    setLoading(false);
  };

  const defValue: IDemandsCandidates = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add DemandsCandidates
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Button type="submit" color="primary">
            Save
          </Button>
        )}
        <Button
          type="button"
          color="secondary"
          onClick={() => props.onCloseHandler("VaryingMdo")}
        >
          Close
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DemandsCandidatesForm;
