import React from "react";
import "./index.scss";
import "./index.css";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";


const Root = () => {
  
  return (
    <Provider store={store} >
     <App></App>
    </Provider>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));
reportWebVitals();
