import { configureStore } from "@reduxjs/toolkit";
import usersSlice from "../components/auth/features/user-slice";
import collegesSlice from "../components/basic-details/colleges/colleges-slice";
import degreesSlice from "../components/basic-details/degree/degrees-slice";
import demandscandidatesSlice from "../components/demand/demands-candidates/features/demandscandidates-slice";
import demandsRecruitersSlice from "../components/demand/demands-recruiters/features/demandsrecruiters-slice";
import {
  demandMatchApi,
  recruitersAssignApi,
} from "../components/demand/demands/features/demands-query";
import demandsSlice from "../components/demand/demands/features/demands-slice";
import questionsSlice from "../components/question/questions/questions-slice";
import candidatesSlice from "../components/users/candidates/candidates-slice";
import accountSlice from "../components/account/accounts/features/accounts-slice";
import {accountRecruitersAssignApi} from "../components/account/accounts/features/accounts-query";
import platformUserSlice from "../components/users/platform-users/store/reducers";
import accountRecruitersSlice from "../components/account/account-recruiters/features/account-recruiters-slice"

import { candidatesStatusApi } from "./apis-service/candidates-status-api";
import appStateSlice from "./appStateSlice";
// import toastSlice from "./toastSlice";

const store = configureStore({
  reducer: {
    appState: appStateSlice,
    usersSlice,
    demandsSlice,
    collegesSlice,
    degreesSlice,
    questionsSlice,
    candidatesSlice,
    platformUserSlice,
    demandscandidatesSlice,
    demandsRecruitersSlice,
    accountSlice,
    accountRecruitersSlice,
    [demandMatchApi.reducerPath]: demandMatchApi.reducer,
    [recruitersAssignApi.reducerPath]: recruitersAssignApi.reducer,
    [candidatesStatusApi.reducerPath]: candidatesStatusApi.reducer,
    [accountRecruitersAssignApi.reducerPath]: accountRecruitersAssignApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    demandMatchApi.middleware,
    recruitersAssignApi.middleware,
    candidatesStatusApi.middleware,
    accountRecruitersAssignApi.middleware,
  ],
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
