import React from "react";
import { authAssignRecruiter } from "../../../../../core/authorization";
import { IUser } from "../../../../../core/interfaces";
import { useAppSelector } from "../../../../../store/hooks";
import Datatable from "../../../../common/datatable";
import LoadingIndicator from "../../../../common/loading";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import { useGetRecruitersQuery } from "../../features/demands-query";
import { ActionButtons } from "./assign-buttons";

function AssignedRecruiters() {
  const { selectedRow } = useAppSelector((state) => state.demandsSlice);
  const { refetch, isFetching, data } = useGetRecruitersQuery(
    JSON.stringify({ _id: selectedRow.assigned_recruiter_id })
  );


  return (
    <>
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <>
        <br/><br/>
         <h3>Assigned Recruiter</h3>
          <Datatable
            height={250}
            customButtons={ActionButtons}
            quikFilter={false}
            quikExport={false}
            actionName={"View"}
            isDefaultShowActions={authAssignRecruiter.hasAccess()}
            columnNames={["full_name","username"]}
            myData={data.data.map((e:IUser) => ({...e,isAssigned : true}))
          }
          />
        </>
      )}
    </>
  );
}

export default AssignedRecruiters;
