
  import React, { FC, useState } from "react";
  import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    ModalFooter,
    Button,
  } from "reactstrap";
  import LoadingIndicator from "../../common/loading";
  import { IQuestions } from "./questions-interface";

const QuestionsForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true)
    props.onSubmitForm(event);
    setLoading(false)
  };

  const defValue: IQuestions = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Questions
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
        <FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Question:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.question}
                className="form-control mb-0"
                name="question"
                id="question-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group row">
    <Label className="col-xl-3 col-sm-4 mb-0">
        Select Question Type:
    </Label>
    <div className="col-xl-8 col-sm-7">
        <Input 
        defaultValue={defValue.question_type}
        type="select" name="question_type" id="question_type-String">
        <option value="abstract_reasoning">Abstract Reasoning</option><option value="numerical_reasoning">Numerical Reasoning</option><option value="verbal_reasoning">Verbal Reasoning</option><option value="logical_reasoning">Logical Reasoning</option><option value="problem_solving">Problem Solving</option><option value="critical_thinking">Critical Thinking</option><option value="decision_making">Decision Making</option><option value="puzzles">Puzzles</option><option value="basic_programming">Basic Programming</option><option value="statistic">Statistic</option><option value="business_knowledge">Business Knowledge</option>
        </Input>
    </div>
</FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Level:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.level}
                className="form-control mb-0"
                name="level"
                id="level-number"
                type="number"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Option 1:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.option_1}
                className="form-control mb-0"
                name="option_1"
                id="option_1-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Option 2:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.option_2}
                className="form-control mb-0"
                name="option_2"
                id="option_2-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Option 3:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.option_3}
                className="form-control mb-0"
                name="option_3"
                id="option_3-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Option 4:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.option_4}
                className="form-control mb-0"
                name="option_4"
                id="option_4-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Answer:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.answer}
                className="form-control mb-0"
                name="answer"
                id="answer-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Answer Description:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.answer_description}
                className="form-control mb-0"
                name="answer_description"
                id="answer_description-textarea"
                type="textarea"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Marks:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.marks}
                className="form-control mb-0"
                name="marks"
                id="marks-number"
                type="number"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        No Of Views:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.no_of_views}
                className="form-control mb-0"
                name="no_of_views"
                id="no_of_views-number"
                type="number"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Difficulty Level Machine:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.difficulty_level_machine}
                className="form-control mb-0"
                name="difficulty_level_machine"
                id="difficulty_level_machine-number"
                type="number"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Difficulty Level Assesment:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.difficulty_level_assesment}
                className="form-control mb-0"
                name="difficulty_level_assesment"
                id="difficulty_level_assesment-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
          <ModalFooter>
         { isLoading ? 
         <LoadingIndicator/>
         :
            <Button type="submit" color="primary">
              Save
            </Button>}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default QuestionsForm;

    