export interface Config {
    backEndHost: string
}

const devConfig : Config =  { backEndHost : "http://localhost:3000" } 
const preprodConfig : Config =  { backEndHost : "https://mentoscale-dev-be.onrender.com" } 
const prodConfig : Config =  { backEndHost : "https://mentoscale-prod-be.onrender.com" } 

export function AppConfigs() {   
    let env = process.env.REACT_APP_ENV || 'development' 
    console.log(" ***** Application Environment ***** \n     ***** ",(env)?.toUpperCase()," ***** \n",);
    switch (env) {
        case 'preprod': return preprodConfig; 
        case 'production': return prodConfig;
        default: return devConfig;
    }
}

