import React from "react";
import { authMatchDemand } from "../../../../../core/authorization";
import { useAppSelector } from "../../../../../store/hooks";
import Datatable from "../../../../common/datatable";
import LoadingIndicator from "../../../../common/loading";
import { ICandidates } from "../../../../users/candidates/candidates-interface";
import { useGetCandidatesByIdsQuery } from "../../features/demands-query";
import { ActionButtons } from "./assign-buttons";

function AssignedCandidates() {
  const { selectedRow } = useAppSelector((state) => state.demandsSlice);
  const { refetch, isFetching, data } = useGetCandidatesByIdsQuery(
    JSON.stringify({ _id: { $in: selectedRow.assigned_candidates } })
  );


  return (
    <>
      {isFetching ? (
        <LoadingIndicator />
      ) : (
        <>
        <br/><br/>
         <h3>Assigned Candidates</h3>
          <Datatable
            height={250}
            customButtons={ActionButtons}
            quikFilter={false}
            quikExport={false}
            actionName={"View"}
            columnNames={["name", "skills"]}
            isDefaultShowActions={authMatchDemand.hasAccess()}
            myData={data.data.map((e:ICandidates) => ({...e,isAssigned : true}))
          }
          />
        </>
      )}
    </>
  );
}

export default AssignedCandidates;
