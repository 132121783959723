
  import React, { FC, useState } from "react";
  import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    ModalFooter,
    Button,
  } from "reactstrap";
import LoadingIndicator from "../../../common/loading";
import { IDemandsRecruiters } from "../features/demandsrecruiters-interface";
  


const DemandsRecruitersForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true)
    props.onSubmitForm(event);
    setLoading(false)
  };

  const defValue: IDemandsRecruiters = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader toggle={props.onCloseHandler}>
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add DemandsRecruiters
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
        <FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Demand Id:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={`${defValue.demand_id}`}
                className="form-control mb-0"
                name="demand_id"
                id="demand_id-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Demand Code:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.demand_code}
                className="form-control mb-0"
                name="demand_code"
                id="demand_code-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Recruiter Id:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={`${defValue.recruiter_id}`}
                className="form-control mb-0"
                name="recruiter_id"
                id="recruiter_id-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Recruiter Name:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.recruiter_name}
                className="form-control mb-0"
                name="recruiter_name"
                id="recruiter_name-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Status:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.status}
                className="form-control mb-0"
                name="status"
                id="status-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>
<FormGroup className="form-group mb-3 row">
        <Label className="col-xl-3 col-sm-4 mb-0">
        Comments:
        </Label>
        <div className="col-xl-8 col-sm-7">
            <Input
                defaultValue={defValue.comments}
                className="form-control mb-0"
                name="comments"
                id="comments-text"
                type="text"
                
            />
        </div>
        <div className="valid-feedback">Looks good!</div>
    </FormGroup>

          <ModalFooter>
         { isLoading ? 
         <LoadingIndicator/>
         :
            <Button type="submit" color="primary">
              Save
            </Button>}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DemandsRecruitersForm;

    