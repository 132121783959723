import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { errorToast, showToast } from "../../../../../store/toastSlice";
import { ViewJSONData } from "../../../../../utils/AppUtils";
import LoadingIndicator from "../../../../common/loading";
import {  useAssignRecruitersMutation } from "../../features/accounts-query";
import { updateAssignedRecruiter } from "../../features/accounts-slice";

export const ActionButtons = (row: any) => {
  let currentRowData;
  let appDispatch = useAppDispatch();
  const [assignRecruiter, { isLoading: isAssigning, status }] =
  useAssignRecruitersMutation();
  // const [isAssigning, assignRecruiter] = useState(false);
  const { selectedRow } = useAppSelector((state) => state.accountSlice);
  const { userInfo } = useAppSelector((state) => state.usersSlice);
  const assignHandler = (row: any) => {
    let assignableObj = {
      company_name : selectedRow.company_name,
      recruiter_id: row.data._id,
      recruiter_name : row.data.full_name,
      is_assigned: row.data.isAssigned,
    }
    assignRecruiter({
      body: assignableObj,
      id: selectedRow._id,
    }).then((data : any) => {
      if(data.data.status == 'Ok'){
      row.api.applyTransaction({ remove: [row.node.data] });
      appDispatch(
        updateAssignedRecruiter(assignableObj)
      );}else{
        errorToast(data.data.message);
      }
    });
  };
 

  if (isAssigning) {
    return (
      <div style={{ width: 25 }}>
        <LoadingIndicator />
      </div>
    );
  }


  return (
    <div>
      
      <Button
        color="primary"
        onClick={() => assignHandler(row)}
        style={{ height: 20, padding: 3, paddingTop: 0, fontSize: 10 }}
      >
        {row.data.isAssigned ? "De Assign" : "Assign"}
      </Button>
    </div>
  );
};
