import { createSlice } from "@reduxjs/toolkit";
  import { IDemandsRecruiters } from "./demandsrecruiters-interface";
    export interface IDemandsRecruitersState {
        isOpen: boolean;
        isEmpty: boolean;
        message: string;
        isBulkOpen: boolean;
        dataList: IDemandsRecruiters[],
        editableData: IDemandsRecruiters,
    }
      
    
    const inialState: IDemandsRecruitersState = {
        isEmpty: false,
        isOpen: false,
        message: "loading",
        isBulkOpen: false,
      dataList: [] as IDemandsRecruiters[],
      editableData: {} as IDemandsRecruiters,
    };
    
    export const DemandsRecruitersSlice = createSlice({
      name: "demandsrecruiters_slice",
      initialState: inialState,
      reducers: {
        setOpen: (state, action) => {
          if(! action.payload) state.editableData = {} as IDemandsRecruiters;
          state.isOpen = action.payload;
        },
        setEmpty: (state, action) => {

          state.isEmpty = action.payload;
        },
        setMessage: (state, action) => {
          state.message = action.payload;
        },
        dataListAppend: (state, action) => {
          state.dataList = [... state.dataList,...action.payload ];
        },
        setDataList: (state, action) => {
          state.dataList = action.payload;
          if (action.payload.length == 0) {
            state.message = "Data Not Available";
            state.isEmpty = true;
          }
        },
        setEditable: (state, action) => {
          state.isOpen = true;
          state.editableData = action.payload;
        },
        setBulkOpen: (state, action) => {
          state.isBulkOpen = action.payload;
        },
      },
    });
    
    export const {
        setOpen,
        setEmpty,
        setMessage,
        dataListAppend,
        setDataList,
        setEditable,
        setBulkOpen
    } = DemandsRecruitersSlice.actions;
    
    export default DemandsRecruitersSlice.reducer;
    