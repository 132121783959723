import store from "../store/store";

export const Authorization = {
  canCreate: () => {
    return menuAccess().includes("CREATE");
  },

  canDelete: () => {
    return menuAccess().includes("UPDATE");
  },

  canUpdate: () => {
    return menuAccess().includes("UPDATE");
  },

  canApprove: () => {
    return menuAccess().includes("APPROVE");
  },

  canMatchDemand: () => {
    return menuAccess().includes("MATCH_DEMAND");
  },
};

function menuAccess() {
  const menuId = store.getState().appState.selectedMenu;
  return store.getState().usersSlice.userInfo.access[menuId];
}

const codes = {
  authMatchDemand : 'MATCH_DEMAND',
  authAssignRecruiter : 'ASSIGN_RECRUITER',
  authApprove : 'APPROVE',
  authReject : 'REJECT',
  authCreate : 'CREATE',
  authUpdate : 'UPDATE'
};


export const { authMatchDemand,
  authAssignRecruiter,
  authApprove,
  authReject,
  authCreate,
  authUpdate ,} =  codes;

String.prototype.hasAccess = function(){
  const menuId = store.getState().appState.selectedMenu;
  return store.getState().usersSlice.userInfo.access[menuId].includes(String(this));
};
