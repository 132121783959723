export const AppPaths = {
  dashboard: "/dashboard",

  //basic details
  bdCollesges: "/basic-details/colleges",
  bdDegrees: "/basic-details/degress-and-courses",
  bdAcademicScores: "/basic-details/academic-score",

  //assesments
  amAssessment: "/assesment/assessment",
  amAnalytics: "/assesment/analytics",

  //Demands
  dDemands: "/demand/demands",
  dDemandsCandidates: "/demand/demands-candidate",
  dDemandsRecruiters: "/demand/assigned-recruiters",
  
  media: "/media",

  //users
  uCandidates: "/users/candidates",
  uRecruiters: "/users/recruiters",
  uSalesTeams: "/users/sales-team",
  uPlatformUsers: "/users/platform-user",
  uforApproval: "/users/for-approval",

  //accounts 
  aAccounts: "/account/accounts",
  aAccountRecruiters: "/account/accounts-recruiters",

  rpReports: "/reports/report",

  stProfile: "/settings/profile",
  stMenuAssigment: '/settings/menu-assignment',

  aulogin: "/auth/login",
  thankYou: "/thank-you",
  
};

String.prototype.fullPath = function () {
  return `${process.env.PUBLIC_URL}${String(this)}`;
};

