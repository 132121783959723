import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import LoadingIndicator from "../../common/loading";
import { IColleges } from "./colleges-interface";

const CollegesForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true);
    props.onSubmitForm(event);
    setLoading(false);
  };

  const defValue: IColleges = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} 
    toggle={props.onCloseHandler}
    backdrop="static"
    >
      <ModalHeader
      //  toggle={props.onCloseHandler}
      
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Colleges
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">College Name:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.college_name}
                className="form-control mb-0"
                name="college_name"
                id="college_name-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">College Id:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.college_id}
                className="form-control mb-0"
                name="college_id"
                id="college_id-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Category:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.category}
                className="form-control mb-0"
                name="category"
                id="category-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Univeristy:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.univeristy}
                className="form-control mb-0"
                name="univeristy"
                id="univeristy-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group row">
            <Label className="col-xl-3 col-sm-4 mb-0">Select State/UT:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.state}
                type="select"
                name="state"
                id="state-String"
              >
                <option value="Andhra Pradesh">Andhra pradesh</option>
                <option value="Arunachal Pradesh">Arunachal pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal pradesh</option>
                <option value="Jammu and Kashmir">Jammu and kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West bengal</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and nicobar islands
                </option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Dadra and Nagar Haveli">
                  Dadra and nagar haveli
                </option>
                <option value="Daman and Diu">Daman and diu</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="National Capital Territory of Delhi">
                  National capital territory of delhi
                </option>
                <option value="Puducherry">Puducherry</option>
              </Input>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Description:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.description}
                className="form-control mb-0"
                name="description"
                id="description-textarea"
                type="textarea"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group row">
            <Label className="col-xl-3 col-sm-4 mb-0">Select Status:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.status}
                type="select"
                name="status"
                id="status-String"
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </Input>
            </div>
          </FormGroup>
          <ModalFooter>
            {isLoading ? (
              <LoadingIndicator />
            ): (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CollegesForm;
