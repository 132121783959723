import Swal from "sweetalert2";
import { DynamicKeyAny } from "../core/interfaces";
const FormDataToJson = (data: FormData) => {
  var object = {} as any;

  data.forEach(function (value, key, parent) {
    object[key] = value === "on" ? "true" : value;
  });
  return object;
};

export const Capitalize = (str: string) => {
  str = str.replace(/_/g, " ");
  let re = /(\b[a-z](?!\s))/g;
  return str.replace(re, function (x) {
    return x.toUpperCase();
  });
};

const JsonToHtmlView = function (json: any, showHeader = true): string {
  let dtRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/
  let hideColumns = ["_id", "createdAt", "updatedAt", "__v", "_id"];

  let content: any[] = [];
  let instance = (elem: any) => {
    if (Array.isArray(elem)) {
      return elem.every((element) => typeof element === "string")
        ? elem.join(",")
        : JsonToHtmlView(elem, false);
    } else {
      return JsonToHtmlView(elem, false);
    }
  };
  for (const key in json) {
    if (!showHeader && hideColumns.includes(key)) {
      continue;
    }
    if (Object.prototype.hasOwnProperty.call(json, key)) {
      const element = json[key];
      content.push(`<tr>
      <td>${Capitalize(key)}</td>
      <th>${
        typeof element == "object" && element != null
          ? instance(element)
          : dtRegex.test(element) ? element.match(dtRegex)[0].replace("T", " ") : element
      }</th>
  </tr>`);
    }
  }

  return `<div>
            <table class="unfixed-table">
               ${
                 showHeader
                   ? "<thead> <tr> <th>Field</th><th>Value</th>  </tr>  </thead>"
                   : ""
               }
                <tbody>
                ${content.join("")}
                </tbody>
            </table>
          </div>`;
};

const ViewJSONData = (data: any) => {
  Swal.fire({
    showCloseButton: true,
    title: "<strong>Details</strong>",
    html: JsonToHtmlView(data),
    customClass: "swal-wide",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Okay',
  });
};

const ActionComfirmation = () =>
  Swal.fire({
    title: "Are you sure?",
    text: "Do you really want to continue this action?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  });

export { FormDataToJson, ViewJSONData, ActionComfirmation };
