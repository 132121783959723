import { Fragment, useEffect } from "react";
import { Download, Upload } from "react-feather";
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Authorization } from "../../../core/authorization";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { errorToast, successToast, warnToast } from "../../../store/toastSlice";
import ApiPaths from "../../../utils/ApiPaths";
import ApiService from "../../../utils/ApiService";
import { FormDataToJson } from "../../../utils/AppUtils";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import LoadingIndicator from "../../common/loading";
import CollegesForm from "./colleges-form";
import { setBulkOpen, setDataList, setEditable, setEmpty, setOpen } from "./colleges-slice";

function Colleges(props: any) {
  const appDispatch = useAppDispatch();
  const { editableData, dataList, isEmpty, message, isOpen, isBulkOpen } = useAppSelector(
    (state) => state.collegesSlice
  );
  const onOpenModal = () => appDispatch(setOpen(true));
  const onCloseModal = () => {
    appDispatch(setOpen(false));
  };

  const fetchList = async (mounted: boolean) => {
    appDispatch(setEmpty(false))
    await ApiService.Get(ApiPaths.colleges)
      .then((res) => {
        if (mounted) {
          if (res.data.status == "Ok") {
            appDispatch(setDataList(res.data.data));
          } else {
            errorToast(res.data.msg);
            appDispatch(setEmpty(true));
          }
        }
      })
      .catch((err) => {
        appDispatch(setEmpty(true));
        errorToast("Connection Error");
      });
  };

  useEffect(() => {
    appDispatch(setDataList([]));
    let mounted = true;
    fetchList(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  const onSubmitForm = async (event: {
    preventDefault: () => void;
    target: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const formData: any = FormDataToJson(new FormData(event.target));

    let res = editableData._id
      ? ApiService.Patch(`${ApiPaths.colleges}/${editableData._id}`, formData)
     : ApiService.Post(ApiPaths.colleges, formData);

    res
      .then((res: any) => {
        if (res.data.success) {
          onCloseModal();
          appDispatch(setDataList([]));
          fetchList(true);
        } else {
          warnToast(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log("error ====> ", err.response);
      });
  };

  const handleDelete = (data: any) => {
    ApiService.Delete(`${ApiPaths.colleges}/${data._id}`)
      .then((res) => {
        successToast(res.data.message);
        appDispatch(setDataList([]));
        fetchList(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleEdit = (data: any) => {
    appDispatch(setEditable(data));
  };

  const bulkAdd = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let path = ApiPaths.collegesBulk;
    ApiService.PostFormData(path, data, false)
      .then((res) => {
        if (res.data.success) {
          appDispatch(setBulkOpen(false));
          appDispatch(setDataList([]));
          fetchList(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const onDownload = () => {
    window.open(
      "https://templates-doc.s3.ap-south-1.amazonaws.com/college_template.csv"
    );
  };

  const onUpload = () => {
    appDispatch(setBulkOpen(true));
  };

  return (
    <Fragment>
      <Breadcrumb title={"Colleges"} parent="users" />

      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="btn-popup pull-right">
                  
                  {Authorization.canCreate() && (
                     <>
                     <Button outline onClick={onDownload}>
                       <Download />{" "}
                     </Button>{" "}
                     <Button outline onClick={onUpload}>
                       <Upload />{" "}
                     </Button>{" "}
                    <Button
                      type="button"
                      color="primary"
                      onClick={onOpenModal}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      Add College
                    </Button></>
                  )}
                </div>
                <div className="btn-popup pull-right">
                  {isOpen && (
                    <>
                      <CollegesForm
                        editableData={editableData}
                        isOpen={isOpen}
                        onCloseHandler={onCloseModal}
                        onSubmitForm={onSubmitForm}
                      ></CollegesForm>
                    </>
                  )}
                </div>
                <Modal
                    isOpen={isBulkOpen}
                    toggle={() =>  appDispatch(setBulkOpen(false))}
                  >
                    <ModalHeader toggle={() =>  appDispatch(setBulkOpen(false))}>
                      <h5
                        className="modal-title f-w-600"
                        id="exampleModalLabel2"
                      >
                        Upload College List
                      </h5>
                    </ModalHeader>
                    <ModalBody>
                      <Form onSubmit={(event:any) => bulkAdd(event)}>
                        <FormGroup className="form-group mb-3 row">
                          <Label className="col-xl-3 col-sm-4 mb-0">
                            CSV-File:
                          </Label>
                          <div className="col-xl-8 col-sm-7">
                            <Input
                              className="form-control mb-0"
                              name="file"
                              id="file"
                              type="file"
                            />
                          </div>
                          <div className="valid-feedback">Looks good!</div>
                        </FormGroup>

                        <ModalFooter>
                          <Button type="submit" color="primary">
                            Save
                          </Button>
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() =>  appDispatch(setBulkOpen(false))}
                          >
                            Close
                          </Button>
                        </ModalFooter>
                      </Form>
                    </ModalBody>
                  </Modal>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  {!(dataList.length > 0) ? (
                    <LoadingIndicator isEmpty={isEmpty} message={message} />
                  ): (
                    <Datatable
                    onSelected={undefined}      
                      title="Colleges"
                      myData={dataList}
                      multiSelectOption={false}
                      pageSize={10}
                      pagination={true}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      myClass={undefined}
                      onPageChanged={undefined}
                      currentPage={undefined}
                      hideColumns={undefined}
                      actionName={"Actions"}
                      customButtons={undefined}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
}

export default Colleges;
