import React from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/man.png";
import { RootState } from "../../../store/store";

const UserPanel = () => {
	const {userInfo} = useSelector((state: RootState) => state.usersSlice);
	return (
		<div>
			<div className="sidebar-user text-center">
				<div>
					<img
						className="img-60 rounded-circle lazyloaded blur-up"
						src={man}
						alt="#"
					/>
				</div>
				<h6 className="mt-3 f-14">{userInfo.full_name}</h6>
				<p>{userInfo.role.replace('_',' ')}</p>
			</div>
		</div>
	);
};

export default UserPanel;
