import React, { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import LoadingIndicator from "../../../common/loading";
import { domainMmodules } from "../../../users/candidates/candidates-form";
import { IDemands } from "../features/demands-interface";
import { damandStatus } from "../status-action";

const DemandsForm = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [platformMmodule, setPlatformModule] = useState("SAP");
  const onSubmit = (event: any) => {
    setLoading(true);
    props.onSubmitForm(event);
    setLoading(false);
  };

  const defValue: IDemands = props.editableData ?? {};
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onCloseHandler}
      backdrop="static"
    >
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Demands
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Account Name:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.account_name}
                className="form-control mb-0"
                name="account_name"
                id="account_name-text"
                type="select"
                required
              >
                <option>UST GLOBAL</option>
                <option>SONY</option>
                <option>HTC</option>
                <option>BCT</option>
                <option>LAPP</option>
                <option>SYNNOVE</option>
                <option>CAPGEMINI</option>
                <option>ACL</option>
                <option>AASHIRVAD</option>
                <option>Websynergy</option>
                <option>EY</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Spoc Recruiter:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.spoc_recruiter}
                className="form-control mb-0"
                name="spoc_recruiter"
                id="spoc_recruiter-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Spoc Recruiter Head:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.spoc_recruiter_head}
                className="form-control mb-0"
                name="spoc_recruiter_head"
                id="spoc_recruiter_head-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Spoc Recruiter Head Email:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.spoc_recruiter_head_email}
                className="form-control mb-0"
                name="spoc_recruiter_head_email"
                id="spoc_recruiter_head_email-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Mentoscale LR Name:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.mentoscale_lr_email}
                className="form-control mb-0"
                name="mentoscale_lr_name"
                id="mentoscale_lr_name-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">
              Mentoscale LR Email:
            </Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.mentoscale_lr_email}
                className="form-control mb-0"
                name="mentoscale_lr_email"
                id="mentoscale_lr_email-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Account Manager:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.account_manager}
                className="form-control mb-0"
                name="account_manager"
                id="account_manager-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Delivery Head:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.delivery_head}
                className="form-control mb-0"
                name="delivery_head"
                id="delivery_head-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Client:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.client}
                className="form-control mb-0"
                name="client"
                id="client-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Date:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={
                  defValue.date && defValue.date.toString().substring(0, 10)
                }
                className="form-control mb-0"
                name="date"
                id="date-text"
                type="date"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Jd Title:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.jd_title}
                className="form-control mb-0"
                name="jd_title"
                id="jd_title-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Jso Number:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.jso_number}
                className="form-control mb-0"
                name="jso_number"
                id="jso_number-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Domain:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.domain}
                className="form-control mb-0"
                name="domain"
                id="domain-text"
                type="select"
                required
              >
                <option>ERP</option>
                <option>Non-ERP</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Platform:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.platform}
                className="form-control mb-0"
                name="platform"
                id="platform-text"
                type="select"
                onChange={(e) => setPlatformModule(e.target.value)}
                required
              >
                <option>SAP</option>
                <option>CSD</option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Module:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.module}
                className="form-control mb-0"
                name="module"
                id="module-text"
                type="select"
                required
              >
                {domainMmodules[platformMmodule].map((e) => (
                  <option>{e}</option>
                ))}
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Skill Category:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.skill_category}
                className="form-control mb-0"
                name="skill_category"
                id="skill_category-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Location:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.location}
                className="form-control mb-0"
                name="location"
                id="location-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Budget:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.budget}
                className="form-control mb-0"
                name="budget"
                id="budget-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Experience:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.experience}
                className="form-control mb-0"
                name="experience"
                id="experience-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Notice Period:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.experience}
                className="form-control mb-0"
                name="notice_period"
                id="notice_period-number"
                type="number"
              />
              (in Days)
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Skill:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.skill}
                className="form-control mb-0"
                name="skill"
                id="skill-text"
                type="text"
                required
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Comments:</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.comments}
                className="form-control mb-0"
                name="comments"
                id="comments-text"
                type="text"
              />
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Visibility</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                defaultValue={defValue.visibility?.toString()}
                className="form-control mb-0"
                name="visibility"
                id="visibility-number"
                type="select"
              >
                <option value={"false"}>RESTRICTED</option>
                <option value={"true"}>UNRESTICTED </option>
              </Input>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-3 col-sm-4 mb-0">Status</Label>
            <div className="col-xl-8 col-sm-7">
              <Input
                required
                className="form-control mb-0"
                name="status"
                id="status-number"
                type="select"
                defaultValue={defValue.status || "ACTIVE"}
              >
                {damandStatus.map((e: string) => (
                  <option key={e}>{e}</option>
                ))}
              </Input>
              
            </div>
            <div className="valid-feedback">Looks good!</div>
          </FormGroup>

          <ModalFooter>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DemandsForm;
