import { createSlice } from "@reduxjs/toolkit";
import { IAppConfig, IAppState } from "../core/interfaces";

const inialState: IAppState = {
  isLoggedIn: false,
  isLoading: false,
  selectedMenu: "",
  menu: [],
  configuration: {} as IAppConfig,
};

export const appStateSlice = createSlice({
  name: "app_state_slice",
  initialState: inialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoggedInState: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload as any[];
    },
    setAppConfiguration: (state, action) => {
      state.configuration = action.payload;
    },
    setRoles: (state, action) => {
      state.configuration.roles = action.payload;
    },
    setSeletedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
  },
});

export const {
  setLoading,
  setLoggedInState,
  setAppConfiguration,
  setMenu,
  setRoles,
  setSeletedMenu,
} = appStateSlice.actions;

export default appStateSlice.reducer;
