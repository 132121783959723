import React, { useState } from "react";
import { Form, FormGroup, Input, Button, FormFeedback } from "reactstrap";
import { FormDataToJson } from "../../../../utils/AppUtils";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { showToast } from "../../../../store/toastSlice";
import ApiPaths from "../../../../utils/ApiPaths";
import ApiService from "../../../../utils/ApiService";
import LoadingIndicator from "../../../common/loading";

type Inputs = {
  current_password: "";
  new_password: "";
  confirm_password: "";
};

function ResetPassword() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const [isLoading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setLoading(true);
    ApiService.Post(`${ApiPaths.platformUserUpdatePassword}`, {
      password: data.confirm_password,
    })
      .then((res: any) => {
        if (res.data.success) {
          setLoading(false);
          Swal.fire(
            "Password Reset Successfull",
            "Please Check Mail for latest Password"
          );
        } else {
          setLoading(false);
          Swal.fire("Password Reset Failure", res.data.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        showToast("Something Went Wrong");
        console.log("error ====> ", err.response);
      });
  };
  return (
    <div className="col-md-7">
      <Form
        className="form-horizontal auth-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="current_password"
          rules={{ required: true }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <FormGroup>
              <Input
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                className="form-control"
                placeholder="Current Password"
                ref={ref}
                invalid={!!error}
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
          )}
        />

        <Controller
          control={control}
          name="new_password"
          rules={{ required: true }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <FormGroup>
              <Input
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                className="form-control"
                placeholder="New Password"
                ref={ref}
                invalid={!!error}
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
          )}
        />

        <Controller
          control={control}
          name="confirm_password"
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <>
              <FormGroup>
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  ref={ref}
                  invalid={!!error}
                />
                <FormFeedback>Passwords do not match</FormFeedback>
              </FormGroup>
            </>
          )}
        />

        <div className="form-button">
          {isLoading ? (
            <div style={{ width: 35 }}>
              <LoadingIndicator />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Reset Password
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default ResetPassword;
