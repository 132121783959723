import React from 'react';
import LoadingGif from "../../assets/images/loading.gif";
const LoadingIndicator = (props) => {
    return (
        <>
        {
            props.isEmpty ?
            <div>
                {props.message}
            </div>
            
            :
            <img
            width="100px"
            src={LoadingGif}
            alt=""
            className="img-fluid blur-up lazyloaded"
        />
        }
        </>
       
    );
};

export default LoadingIndicator;