import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../core/interfaces";

export interface IUserState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  userInfo: IUser;
  selectedRow: IUser;
  isShowMatch: boolean;
  query: any;
}

const inialState: IUserState = {
  isEmpty: false,
  isOpen: false,
  message: "loading...",
  userInfo: {} as IUser,
  selectedRow: {} as IUser,
  isShowMatch: false,
  query: {},
};

export const usersSlice = createSlice({
  name: "Users_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      
      state.isOpen = action.payload;
    },
    setShowMatch: (state, action) => {
      // if (!action.payload) state.selectedRow = {} as IUser;
      state.isShowMatch = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
   
    removeUserInfo: (state) => {
      state.userInfo = {} as IUser;
    },

    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setSelected: (state, action) => {
      state.selectedRow = action.payload || ({} as IUser);
    },
    setQuery: (state, action) => {
      state.query = action.payload || {};
    },
  },
});


export const {
  setOpen,
  setEmpty,
  setMessage,
  setSelected,
  setShowMatch,
  setQuery,
  removeUserInfo,
  setUserInfo
} = usersSlice.actions;

export default usersSlice.reducer;
