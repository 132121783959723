import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import { Configs } from "../../../../assets/data/configs";
import { IUserState } from "../../../../core/interfaces";
import LoadingIndicator from "../../../common/loading";

export default function RoleForm(props: any) {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (event: any) => {
    setLoading(true);
    props.onSubmitForm(event);
    setLoading(false);
  };

  const defValue: IUserState = props.editableData ?? {};
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCloseHandler} backdrop="static">
      <ModalHeader //  toggle={props.onCloseHandler}
      >
        {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2"> */}
        Add Platform Role
        {/* </h5> */}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(event) => onSubmit(event)}>
          <FormGroup>
            <Input
              required
              name="name"
              type="text"
              className="form-control"
              placeholder="Role Name"
              id="exampleInputEmail12"
            />
          </FormGroup>
          <ModalFooter>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              onClick={() => props.onCloseHandler("VaryingMdo")}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}
