import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { errorToast, showToast } from "../../../../../store/toastSlice";
import ApiPaths from "../../../../../utils/ApiPaths";
import ApiService from "../../../../../utils/ApiService";
import { ViewJSONData } from "../../../../../utils/AppUtils";
import LoadingIndicator from "../../../../common/loading";
import { useAssignCanditatesMutation } from "../../features/demands-query";
import { updateAssignedCandidate } from "../../features/demands-slice";

export const ActionButtons = (row: any) => {
  let currentRowData;
  let appDispatch = useAppDispatch();
  const [assignCandidate, { isLoading: isAssigning, status }] =
    useAssignCanditatesMutation();
  // const [isAssigning, assignCandidate] = useState(false);
  const { selectedRow } = useAppSelector((state) => state.demandsSlice);
  const { userInfo } = useAppSelector((state) => state.usersSlice);
  const assignHandler = async (row: any) => {
    if (
      userInfo.role == "POWER_NETWORKER" &&
      userInfo._id != row.data.added_by &&
      row.data.isAssigned
    ) {
      Swal.fire(
        "Warning!!!",
        "This Candidate is not assigned by you so you can't de-assigned it"
      );
      return;
    }
    try {
      let canAssign = (
        await ApiService.Get(
          `${ApiPaths.demandsCandidateCanAssignMore}/${row.data._id}`
        )
      ).data;
      if (canAssign.success) {
        if (canAssign.data && !canAssign.data.canAssign) {
          Swal.fire("Warning!!!", "Cannot assigned more than 5 candidates");
          return;
        }
      } else {
        Swal.fire("Warning!!!", canAssign.message);
        return;
      }
    } catch (error) {
      Swal.fire("Warning!!!", `Cannot assigned Something Went Wrong`);
      return;
    }
    if (selectedRow.assigned_candidates.length === 5 && !row.data.isAssigned) {
      Swal.fire("Warning!!!", "Cannot assigned more than 5 candidates");
      return;
    }

    assignCandidate({
      body: {
        assigned_candidates: row.data._id,
        is_assigned: row.data.isAssigned,
      },
      id: selectedRow._id,
    }).then((data: any) => {
      if (data.data.status == "Ok") {
        row.api.applyTransaction({ remove: [row.node.data] });
        appDispatch(
          updateAssignedCandidate({
            is_assigned: row.data.isAssigned,
            assigned_candidates: row.data._id,
          })
        );
      } else {
        errorToast(data.data.message);
      }
    });
  };

  if (isAssigning) {
    return (
      <div style={{ width: 25 }}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div>
      <span onClick={() => ViewJSONData(row.data)}>
        <i
          className="fa fa-eye"
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: "#0000ff",
          }}
        ></i>
      </span>

      <Button
        color="primary"
        onClick={() => assignHandler(row)}
        style={{ height: 20, padding: 3, paddingTop: 0, fontSize: 10 }}
      >
        {row.data.isAssigned ? "De Assign" : "Assign"}
      </Button>
    </div>
  );
};
