import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { successToast, warnToast } from "../../store/toastSlice";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Authorization } from "../../core/authorization";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { ActionComfirmation, Capitalize, ViewJSONData } from "../../utils/AppUtils";
import { IActionColumns } from "../../core/interfaces";


interface IDatatableProps {
  myData: any[];
  myClass?: string;
  multiSelectOption?: boolean;
  pagination?: boolean;
  onEdit?: any;
  onDelete?: any;
  title?: string;
  pageSize?: number;
  totalCount?: number;
  onPageChanged?: any;
  currentPage?: any;
  hideColumns?: string[];
  columnNames?: string[];
  customButtons?: any;
  actionName?: string;
  onSelected?: any;
  quikFilter?: boolean;
  quikExport?: boolean;
  actionColumns?: IActionColumns[],
  selectionColumn?: string;
  height?: number;
  isDefaultShowActions?: boolean
}
const Datatable = ({
  height = 500,
  quikFilter = true,
  quikExport = true,
  selectionColumn,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  onEdit,
  onDelete,
  title,
  pageSize,
  totalCount = 5,
  onPageChanged,
  currentPage,
  hideColumns,
  customButtons,
  actionName,
  actionColumns,
  onSelected,
  columnNames,
  isDefaultShowActions
}: IDatatableProps) => {
  const isShowActions = isDefaultShowActions || Authorization.canUpdate();
  const gridRef = useRef<AgGridReact>(null);

  const [rowData, updateData] = useState(
    myData.map((e) => {
      let obj : any = {};
      if(e.hasOwnProperty('is_active')){
        obj.is_active = e.is_active ? 'ACTIVE' : 'INACTIVE'
      }
      if(e.hasOwnProperty('is_approved')){
        obj.is_approved = e.is_active ? 'APPROVED' : 'NOT APPROVED'
      }
      return { ...e, action: "" ,...obj};
    })
  );



  let actionButtons = (row: any) => {
    row = row.data;
    return (
      <div>
        <span onClick={() => handleDelete(row)}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
            }}
          ></i>
        </span>

        <span onClick={() => handleEdit(row)}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}
          ></i>
        </span>
      </div>
    );
  };

  const isHide = (key: string) => {
    const defaultHideColumns = ["__v",'_id'];
    const hiddenColumns = hideColumns ? [...defaultHideColumns, ...hideColumns] : defaultHideColumns;
    return hiddenColumns.includes(key);
  };

  const onSelectionChanged = useCallback((event) => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (onSelected && selectedRows) {
      onSelected(multiSelectOption ? selectedRows : selectedRows[0]);
    }
  }, []);

  const cellRenderer = (action : any, field_name : string) => {
    return {
      headerName: Capitalize(field_name),
      field: field_name,
      hide: isHide(field_name),
      cellRenderer: action,
    };
  }
  const [columnDefs] = useState([
    ...(columnNames || Object.keys(myData[0])).map((key) => {
      if (
        actionColumns
      ) {
        let act = actionColumns.find((e) => (e.columnName == key && e.isActive));
        if (act) {
          return cellRenderer(act.customAction, act.columnName);
        }
      }
      return {
        checkboxSelection: onSelected && key == (selectionColumn || "_id"),
        headerName: Capitalize(key),
        field: key,
        sortable: true,
        filter: true,
        hide: isHide(key),
      };
    }),
    !isShowActions
      ? {}
      : {
        headerName: actionName ?? "Action",
        field: "action",
        hide: isHide("action"),
        cellRenderer: customButtons ?? actionButtons,
      },
  ]);

  const handleDelete = async (row: any) => {
    let result = await ActionComfirmation()
    if(!result.value){ return}
      onDelete(row);
  
  };

  const handleEdit = (index: number) => {
    
    onEdit(index);
  };

  const defaultColDef = useMemo(() => {
    return {
      // editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,

      minWidth: 150,
    };
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current?.api.exportDataAsCsv();
  }, []);

  const onChangePage = (params: number) => {
    onPageChanged(params + 1);
  };

  const onQuickFilterChanged = useCallback(() => {
    gridRef.current?.api.setQuickFilter(
      (document.getElementById("quickFilter") as HTMLInputElement).value
    );
  }, []);

  let onRowDoubleClicked = (rowData: any) => {
    let row = JSON.parse(JSON.stringify(rowData.data));
    for (const key in row) {
      if (isHide(key) && typeof row[key] != 'object') {
        delete row[key]
      }
    }
    delete row.action;
    ViewJSONData(row)
    
  };

  return (
    <Row>
      <Col sm="12">
        {quikExport && (
          <Button color="primary" onClick={onBtnExport}>
            Export CSV
          </Button>
        )}
        {quikFilter && (
          <div className="btn-popup pull-right">
            <input
              type="text"
              className="form-control"
              onInput={onQuickFilterChanged}
              id="quickFilter"
              placeholder="quick filter..."
            />
          </div>
        )}
      </Col>

      <br></br>
      <Col
        sm="12"
        className="ag-theme-alpine"
        style={{ height: height, width: "100%" }}
      >
        <AgGridReact
          key={"test"}
          alwaysShowHorizontalScroll={true}
          suppressHorizontalScroll={true}
          onRowDoubleClicked={onRowDoubleClicked}
          // rowHeight={35}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={pagination}
          defaultColDef={defaultColDef}
          paginationAutoPageSize={true}
          rowSelection={multiSelectOption ? "multiple" : "single"}
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </Col>
    </Row>
  );
};

export default Datatable;
