import axios from "axios";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppConfigs } from "../AppConfigs";


const RemoteApi = AppConfigs().backEndHost; //dev
const ApiService = {};

const createMsg = (err) => {
  let  msg =  ( err.response && err.response.data && err.response.data.message ) ? 
        (Array.isArray(err.response.data.message) ? err.response.data.message.join("\n") : err.response.data.message)
        : err.response.statusText
    return msg;
}

ApiService.Get = async (path) => {
  const id = toast.loading("Please wait...")
  // let res = await axios.get(RemoteApi + path, { headers: ApiHeaders() });
  return new Promise((resolve, reject) => {
    axios
    .get(RemoteApi + path, { headers: ApiHeaders() })
      .then((res) => {
        toast.update(id, {render: res.data.message, type: "success", isLoading: false, autoClose: 100});
        resolve(res);
      })
      .catch((err) => {

        toast.update(id, {render: createMsg(err), type: "error", isLoading: false , autoClose: 5000});
        reject(err);
      });
  });
};

ApiService.Post = async (path, data) => {
  const id = toast.loading("Please wait...")
  return new Promise((resolve, reject) => {
    axios
      .post(RemoteApi + path, data, { headers: ApiHeaders() })
      .then((res) => {
        toast.update(id, {render: res.data.message, type: "success", isLoading: false, autoClose: 100});
        resolve(res);
      })
      .catch((err) => {
       toast.update(id, {render: createMsg(err), type: "error", isLoading: false , autoClose: 3000});
        reject(err);
      });
  });
};

ApiService.Delete = async (path) => {
  const id = toast.loading("Please wait...")
  return new Promise((resolve, reject) => {
    axios
      .delete(RemoteApi + path, { headers: ApiHeaders() })
      .then((res) => {
        toast.update(id, {render: res.data.message, type: "success", isLoading: false, autoClose: 100});
        toast.dismiss();
        resolve(res);
      })
      .catch((err) => {

        toast.update(id, {render: createMsg(err), type: "error", isLoading: false , autoClose: 5000});
        reject(err);
      });
  });
};

ApiService.Patch = async (path, data) => {
  const id = toast.loading("Please wait...")
  return new Promise((resolve, reject) => {
    axios
      .patch(RemoteApi + path, data, { headers: ApiHeaders() })
      .then((res) => {
        toast.update(id, {render: res.data.message, type: "success", isLoading: false, autoClose: 100});
        toast.dismiss();
        resolve(res);
      })
      .catch((err) => {
     
        toast.update(id, {render: createMsg(err), type: "error", isLoading: false , autoClose: 5000});
        reject(err);
      });
  });
};

const ApiHeaders = () => {
  var userInfo = reactLocalStorage.getObject("userInfo");
  let headersOpts = {};
  headersOpts["Content-Type"] = "application/json";
  headersOpts["Accept"] = "application/json";
  headersOpts["Access-Control-Allow-Origin"] = true;
  if (userInfo) {
    headersOpts.Authorization = `Bearer ${userInfo.token}`;
  }
  return headersOpts;
}

ApiService.PostFormData = async (path, data, isPut = false) => {
  const lHeaders = {};
  lHeaders.Authorization = ApiHeaders().Authorization;
  let res = await (isPut
    ? axios.put(RemoteApi + path, data, { headers: lHeaders })
    : axios.post(RemoteApi + path, data, { headers: lHeaders }));

  return res;
};

export default ApiService;
export { ApiHeaders }; 
