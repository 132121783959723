import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { setMenu } from "../../store/appStateSlice";
import { RootState } from "../../store/store";
import { errorToast } from "../../store/toastSlice";
import ApiPaths, { ConfigIds } from "../../utils/ApiPaths";
import ApiService from "../../utils/ApiService";

interface IAccessRouter {
  path?: string;
  children: JSX.Element;
}
function AccessController({ children }: IAccessRouter) {
  const {userInfo} = useSelector((state: RootState) => state.usersSlice);
  const appMenu: any[] = useSelector(
    (state: RootState) => state.appState.menu
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (appMenu.length === 0 ) {
      const appMenuSaved: any[] = (reactLocalStorage.getObject("menus") as any).items;
      if (!appMenuSaved || appMenuSaved.length === 0) {
        fetchAppConfiguration();
      } else {
        dispatch(setMenu(appMenuSaved));
      }
    }
  }, [!!userInfo.role]);

  const fetchAppConfiguration = async () => {
    ApiService.Get(`${ApiPaths.appConfiguration}/${ConfigIds.menuId}`)
      .then((res) => {
        res = res.data;
        if (res.code === 200) {
          let items = res.data[0].items;
          reactLocalStorage.setObject("menus", {items});
          dispatch(setMenu(items));
        } else {
          errorToast(res.message);
        }
      })
      .catch((err) => {
        console.log("error", err);
        errorToast("Something went wrong");
      });
    //
  };

  if (!!!userInfo.role) {
    return <Navigate to="/" replace />;
  }
  return children;
}

export default AccessController;
