
import { toast } from "react-toastify";

export const errorToast = (message: string ) => {
  
  toast.error(message, {autoClose:5000});
};
export const successToast = (message: string) => {
  toast.success(message);
};
export const warnToast = (message: string) => {
  toast.warn(message);
};
export const infoToast = (message: string) => {
  toast.info(message);
};
export const showToast = (message: string) => {
  toast(message);
};
