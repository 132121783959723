import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { IMenuItem, IRoles } from "../../../core/interfaces";
import { RootState } from "../../../store/store";
import Breadcrumb from "../../common/breadcrumb";
import MenuContainer, { ContainerCard } from "./components/menu-container";
import * as _ from "lodash";
import "./components/menu-container.scss";
import RoleForm from "./components/role-form";
import {
  fetechRoles,
  onCheckedHandler,
  onCloseModal,
  onOpenModal,
  onSelectorHandler,
  onSubmitForm,
  updateHandler,
} from "./store/reducers";
import { first } from "lodash";

export const MenuAssignment = (props: any) => {
  const crud = ["CREATE", "UPDATE"];

  const [open, setOpen] = useState(false);
  const configMenus = useSelector((state: RootState) => state.appState.menu);
  const iRoles = useSelector(
    (state: RootState) => state.appState.configuration.roles
  );
  const dispatch =  useDispatch()

  useEffect(() => {
    fetechRoles(dispatch)
  }, [])
  const [roles, updateRoles] = useState([
    ...(JSON.parse(JSON.stringify(iRoles)))
  ]);

  return (
    <>
      {open && (
        <>
          <RoleForm
            isOpen={open}
            onCloseHandler={() => onCloseModal(setOpen)}
            onSubmitForm={(event: any) => onSubmitForm(setOpen, event)}
          ></RoleForm>
        </>
      )}
      <Breadcrumb title={"Menu Assignment"} parent={"settings"}></Breadcrumb>
      <Container fluid={true}>
        <Card>
          <CardTitle className="ma-card-title">
            Select Roles{" "}
            <div className="btn-popup pull-right">
              <Button
                type="button"
                color="primary"
                onClick={() => onOpenModal(setOpen)}
                data-toggle="modal"
                data-original-title="test"
                data-target="#exampleModal"
              >
                Add Role
              </Button>
            </div>
          </CardTitle>
          <CardBody>
            {roles.map((role: IRoles) => {
              return (
                <>
                  <Input
                    className="checkbox_animated"
                    id={`chk-ani-role-selector-${role.role_id}`}
                    name={role.role_id}
                    type="checkbox"
                    defaultChecked={role.is_checked}
                    onChange={(event) => {
                      const t = event.target;
                      onSelectorHandler(
                        t.checked,
                        role.role_id,
                        roles,
                        updateRoles
                      );
                    }}
                  />
                  <span className="role_name">{role.role_id}</span>
                </>
              );
            })}
          </CardBody>
        </Card>
        <Row style={{ flexWrap: "nowrap", overflowX: "auto" }}>
          {roles.map((role : IRoles) => {
            return !role.is_checked ? (
              <></>
            ) : (
              <ContainerCard
                key={role.role_id}
                title={role.role_id}
                onUpdateClick={() => updateHandler(role.role_id, roles, setOpen)}
              >
                {configMenus.map((item: IMenuItem) => {
                
                  return (
                    <>
                     
                      <MenuContainer
                        menuItem={item}
                        selectedMenu={role.access}
                        key={`${role.role_id}__${item.menu_id}`}
                        disabled={false}
                        parent={`${role.role_id}__${item.menu_id}`}
                        crud={crud}
                        onChanged={(event: any) => {
                          const t = event.target;
                          console.log(t);

                          onCheckedHandler(
                            roles,
                            updateRoles,
                            t.checked,
                            t.name,
                            role.role_id,
                            item.children?.map((ci) => ci.menu_id)
                          );
                        }}
                        level={0}
                      />
                      <hr />
                    </>
                  );
                })}
              </ContainerCard>
            );
          })}
        </Row>
      </Container>
    </>
  );
};


