import { createSlice } from "@reduxjs/toolkit";
import { IDemands } from "./demands-interface";
export interface IDemandsState {
  isOpen: boolean;
  isEmpty: boolean;
  message: string;
  dataList: IDemands[];
  editableData: IDemands;
  selectedRow: IDemands;
  isShowMatch: boolean;
  isShowAssignRecruiters: boolean;
  query: any;
  recruiterQuery: any;
}

let defaultRecruiterQuery = { role: "RECRUITER", region: "NORTH" };

const inialState: IDemandsState = {
  isEmpty: false,
  isOpen: false,
  message: "loading...",
  dataList: [] as IDemands[],
  editableData: {} as IDemands,
  selectedRow: {} as IDemands,
  isShowMatch: false,
  isShowAssignRecruiters: false,
  query: {},
  recruiterQuery: defaultRecruiterQuery,
};

export const DemandsSlice = createSlice({
  name: "demands_slice",
  initialState: inialState,
  reducers: {
    setOpen: (state, action) => {
      if (!action.payload) state.editableData = {} as IDemands;
      state.isOpen = action.payload;
    },
    setShowMatch: (state, action) => {
      state.isShowMatch = action.payload;
    },
    setShowAssignRecruiters: (state, action) => {
      state.isShowAssignRecruiters = action.payload;
    },
    setEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    dataListAppend: (state, action) => {
      state.dataList = [...state.dataList, ...action.payload];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
      if (action.payload.length == 0) {
        state.message = "Data Not Available";
        state.isEmpty = true;
      }
    },
    setEditable: (state, action) => {
      state.isOpen = true;
      state.editableData = action.payload;
    },
    setSelected: (state, action) => {
      state.selectedRow = action.payload || ({} as IDemands);
    },
    setQuery: (state, action) => {
      state.query = action.payload || {};
    },
    setRecruiterQuery: (state, action) => {
      state.recruiterQuery = action.payload || defaultRecruiterQuery;
    },

    updateAssignedRecruiter: (state, action) => {
      if (action.payload.is_assigned) {
        state.selectedRow.assigned_recruiter_id = "";
        state.selectedRow.assigned_recruiter_name = "UNASSIGNED";
      } else {
        state.selectedRow.assigned_recruiter_id = action.payload.recruiter_id;
        state.selectedRow.assigned_recruiter_name =
          action.payload.recruiter_name;
      }
    },

    updateAssignedCandidate: (state, action) => {
      if (action.payload.is_assigned) {
        const index = state.selectedRow.assigned_candidates.indexOf(
          action.payload.assigned_candidates
        );
        state.selectedRow.assigned_candidates.splice(index, 1);
      } else {
        state.selectedRow.assigned_candidates.push(
          action.payload.assigned_candidates
        );
      }
    },
  },
});

export const {
  setOpen,
  setEmpty,
  setMessage,
  dataListAppend,
  setDataList,
  setEditable,
  setSelected,
  setShowMatch,
  setQuery,
  updateAssignedCandidate,
  setShowAssignRecruiters,
  updateAssignedRecruiter,
  setRecruiterQuery
} = DemandsSlice.actions;

export default DemandsSlice.reducer;
