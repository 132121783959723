import React, { Fragment, useEffect, useState } from "react";

import { User, Unlock } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ApiService from "../../utils/ApiService";
import { FormDataToJson } from "../../utils/AppUtils";
import { reactLocalStorage } from "reactjs-localstorage";
import { Configs } from "../../assets/data/configs";
import ApiPaths, { ConfigIds } from "../../utils/ApiPaths";
import { errorToast, warnToast } from "../../store/toastSlice";
import { AppPaths } from "../../core/navigation/app-paths";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setAppConfiguration,
  setLoggedInState,
  setMenu,
} from "../../store/appStateSlice";
import { useAppDispatch } from "../../store/hooks";
import ForgotForm from "./components/forgot-form";
import { setOpen, setUserInfo } from "./features/user-slice";

const LoginTabset = () => {
  const history = useNavigate();
  const userState = useSelector((state: RootState) => state.usersSlice);
  const appDispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(1);

  const dispatch = useDispatch();
  const clickActive = (val: number) => {
    setActiveTab(val);
  };

  const routeChange = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    ApiService.Post(ApiPaths.platformUserLogin, FormDataToJson(data))
      .then((res) => {
        res = res.data;
        if (res.code === 200) {
          reactLocalStorage.setObject("userInfo", res.data);
          appDispatch(setUserInfo(res.data));
          history(AppPaths.dashboard.fullPath());
        } else {
          errorToast(res.message);
        }
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
    //
  };

  const register = async (event: any) => {
    event.preventDefault();
    const formData: any = FormDataToJson(new FormData(event.target));
    formData.terms_and_conditions = formData.terms_and_conditions == "on";
    ApiService.Post(ApiPaths.platformUser, formData)
      .then((res) => {
        res = res.data;
        if (res.code === 200) {
          history(`/thank-you`);
        } else {
          warnToast(res.message);
        }
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
  };
  return (
    <div>
      <ForgotForm/>
      <Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == 1 ? "active" : ""}
              onClick={() => clickActive(1)}
            >
              <User />
              Login
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab == 2 ? "active" : ""}
              onClick={() => clickActive(2)}
            >
              <Unlock />
              Register
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <br />
          <TabPane tabId={1}>
            <Form
              className="form-horizontal auth-form"
              onSubmit={(event) => routeChange(event)}
            >
              <FormGroup>
                <Input
                  required
                  name="username"
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  id="exampleInputEmail1"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
              </FormGroup>
              <Row>
                <div className="form-terms">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <Input type="checkbox" className="custom-control-input" />
                    <Label className="d-block">
                      <Input className="checkbox_animated" type="checkbox" />
                      Remember Me{" "}
                    </Label>
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  <span className="pull-right">
                    {" "}
                    <a
                      onClick={() => {
                        appDispatch(setOpen(true))
                      }}
                      className="p-0"
                    >
                      Forgot Password?
                    </a>
                  </span>
                </div>
              </Row>

              <div className="form-button">
                <Button color="primary" type="submit">
                  Login
                </Button>
              </div>
              <div className="form-footer">
                {/* <span>Or Login up with social platforms</span>
                <ul className="social">
                  <li>
                    <a href="/#">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-twitter-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-pinterest-alt"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </Form>
          </TabPane>
          <TabPane tabId={2}>
            <Form
              className="form-horizontal auth-form"
              onSubmit={(event) => register(event)}
            >
              <FormGroup>
                <Input
                  required
                  name="full_name"
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  name="username"
                  type="email"
                  className="form-control"
                  placeholder="Email (Mentoscale ID)"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  name="mobile_no"
                  type="number"
                  className="form-control"
                  placeholder="Mobile No."
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  name="region"
                  type="select"
                  className="form-control"
                  placeholder="Region"
                >
                  {Configs.regions.map((e) => (
                    <option key={e.name}>{e.name}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  required
                  name="role"
                  type="select"
                  className="form-control"
                  placeholder="Role"
                >
                  {Configs.roles.map((e) => (
                    <option key={e.name}>{e.name}</option>
                  ))}
                </Input>
              </FormGroup>
              <div className="form-terms">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Input type="checkbox" className="custom-control-input" />
                  <Label className="d-block">
                    <Input
                      required
                      name="terms_and_conditions"
                      className="checkbox_animated"
                      type="checkbox"
                    />
                    I agree all statements in{" "}
                    <span>
                      <a href="/#">Terms &amp; Conditions</a>
                    </span>
                  </Label>
                </div>
              </div>
              <div className="form-button">
                <Button color="primary" type="submit">
                  Register
                </Button>
              </div>
            </Form>
          </TabPane>
        </TabContent>
      </Fragment>
    </div>
  );
};

export default LoginTabset;
